import 'firebase/auth'

import { useAuth, auth } from 'reactfire'
import { StyledFirebaseAuth } from 'react-firebaseui'

export function Login() {
    const firebaseAuth = useAuth()

    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            auth.GoogleAuthProvider.PROVIDER_ID,
            auth.EmailAuthProvider.PROVIDER_ID,
        ],
    }
    return (
        <div className="px-4">
            <p>
                You must be logged in to view this application. Please log in
                with your Smash Delta Google account or Transport email.
            </p>

            <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebaseAuth}
            />
        </div>
    )
}
