import * as Controls from '../ds/Controls'
import { useOwnerStore } from '../data/ownerStore'
import { useDataStore } from '../data/store'
import { useIssuesStore, MyIssue } from '../data/issuesStore'
import Select from 'react-select'

interface IssueOption {
    label: string
    value: MyIssue
    isDisabled?: boolean
}

export function EcosystemControls() {
    const {
        ready: ownersReady,
        branches,
        sections,
        subSections,
        selectedOwnerIDs,
        toggleOwnerID,
    } = useOwnerStore()

    const {
        ready: issuesReady,
        issueTypes,
        toggleIssueType,
        selectedIssueTypes,

        issueSections,
        toggleIssueSection,
        selectedIssueSections,

        issues,
        specificIssue,
        setSpecificIssue,
    } = useIssuesStore()

    const highlightTBDs = useDataStore((s) => s.highlightTBDs)
    const toggleHighlightTBDs = useDataStore((s) => s.toggleHighlightTBDs)
    const highlightIssues = useDataStore((s) => s.highlightIssues)
    const clearIssueHighlights = useDataStore((s) => s.clearIssueHighlights)

    if (!(ownersReady && issuesReady)) {
        return null
    }

    const issueOptions: IssueOption[] = issues.map((d) => ({
        value: d,
        label: `Issue ${d.issueNumber}`,
        isDisabled: !(d.edgeLinks.length > 0 || d.nodeLinks.length > 0),
    }))

    function onIssueChange(option: IssueOption | null) {
        if (!option) {
            clearIssueHighlights()
            setSpecificIssue(null)
        } else {
            const issue = option.value
            highlightIssues(issue.nodeLinks, issue.edgeLinks)
            setSpecificIssue(issue)
        }
    }

    return (
        <Controls.Panel>
            <Controls.CollapsibleRoot>
                <Controls.CollapsibleTitle>
                    Node Controls
                </Controls.CollapsibleTitle>
                <Controls.Description>
                    Use the following toggles to fade in / out different nodes.{' '}
                    <br />
                </Controls.Description>
                <Controls.Description>
                    Click title expand / collapse
                </Controls.Description>
                <Controls.CollapsibleContent>
                    <Controls.Group>
                        <Controls.GroupTitle>
                            Filter by Owner
                        </Controls.GroupTitle>
                        {branches.map((d) => (
                            <Controls.CheckboxRow key={d._id}>
                                <Controls.Checkbox
                                    id={d.name}
                                    checked={selectedOwnerIDs[d._id]}
                                    onCheckedChange={() => toggleOwnerID(d._id)}
                                >
                                    <Controls.Indicator>
                                        <Controls.Icon />
                                    </Controls.Indicator>
                                </Controls.Checkbox>
                                <Controls.Label htmlFor={d.name}>
                                    {d.name}
                                </Controls.Label>
                            </Controls.CheckboxRow>
                        ))}
                        {sections.map((d) => (
                            <Controls.CheckboxRow key={d._id}>
                                <Controls.Checkbox
                                    id={d.name}
                                    checked={selectedOwnerIDs[d._id]}
                                    onCheckedChange={() => toggleOwnerID(d._id)}
                                >
                                    <Controls.Indicator>
                                        <Controls.Icon />
                                    </Controls.Indicator>
                                </Controls.Checkbox>
                                <Controls.Label htmlFor={d.name}>
                                    {d.name}
                                </Controls.Label>
                            </Controls.CheckboxRow>
                        ))}
                        {subSections.map((d) => (
                            <Controls.CheckboxRow key={d._id}>
                                <Controls.Checkbox
                                    id={d.name}
                                    checked={selectedOwnerIDs[d._id]}
                                    onCheckedChange={() => toggleOwnerID(d._id)}
                                >
                                    <Controls.Indicator>
                                        <Controls.Icon />
                                    </Controls.Indicator>
                                </Controls.Checkbox>
                                <Controls.Label htmlFor={d.name}>
                                    {d.name}
                                </Controls.Label>
                            </Controls.CheckboxRow>
                        ))}
                    </Controls.Group>
                    <Controls.GroupSeparator />
                    <Controls.Group>
                        <Controls.GroupTitle>Highlight TBD</Controls.GroupTitle>
                        <Controls.CheckboxRow>
                            <Controls.Checkbox
                                id="TBD"
                                checked={highlightTBDs}
                                onCheckedChange={toggleHighlightTBDs}
                            >
                                <Controls.Indicator>
                                    <Controls.Icon />
                                </Controls.Indicator>
                            </Controls.Checkbox>
                            <Controls.Label htmlFor="TBD">
                                Highlight TBD Nodes
                            </Controls.Label>
                        </Controls.CheckboxRow>
                    </Controls.Group>
                </Controls.CollapsibleContent>
            </Controls.CollapsibleRoot>

            <Controls.SectionSeperator />

            <Controls.CollapsibleRoot>
                <Controls.CollapsibleTitle>
                    Issue Controls
                </Controls.CollapsibleTitle>
                <Controls.Description>
                    Use the following controls to fade in / out issue icons by
                    type or section. Or select a specific issue from the select
                    box and see its impact on the ecosystem.
                </Controls.Description>
                <Controls.Description>
                    Click title expand / collapse
                </Controls.Description>
                <Controls.CollapsibleContent>
                    <Controls.Group>
                        <Controls.GroupTitle>
                            Show Issue Impact
                        </Controls.GroupTitle>
                        <Select
                            name="specificIssue"
                            options={issueOptions}
                            isClearable
                            isSearchable
                            onChange={onIssueChange}
                        />
                    </Controls.Group>
                    {specificIssue && (
                        <Controls.Group>
                            <Controls.Description>
                                Issue {specificIssue.issueNumber} <br />
                                {specificIssue.name}
                            </Controls.Description>
                            <Controls.Description>
                                {specificIssue.description}
                            </Controls.Description>
                        </Controls.Group>
                    )}
                    <Controls.GroupSeparator />
                    <Controls.Group>
                        <Controls.GroupTitle>
                            Filter by Issue Type
                        </Controls.GroupTitle>
                        {issueTypes.map((id) => (
                            <Controls.CheckboxRow key={id}>
                                <Controls.Checkbox
                                    id={id}
                                    checked={selectedIssueTypes.has(id)}
                                    onCheckedChange={() => toggleIssueType(id)}
                                >
                                    <Controls.Indicator>
                                        <Controls.Icon />
                                    </Controls.Indicator>
                                </Controls.Checkbox>
                                <Controls.Label htmlFor={id}>
                                    {id}
                                </Controls.Label>
                            </Controls.CheckboxRow>
                        ))}
                    </Controls.Group>
                    <Controls.GroupSeparator />
                    <Controls.Group>
                        <Controls.GroupTitle>
                            Filter by Issue Section
                        </Controls.GroupTitle>
                        {issueSections.map((id) => (
                            <Controls.CheckboxRow key={id}>
                                <Controls.Checkbox
                                    id={id}
                                    checked={selectedIssueSections.has(id)}
                                    onCheckedChange={() =>
                                        toggleIssueSection(id)
                                    }
                                >
                                    <Controls.Indicator>
                                        <Controls.Icon />
                                    </Controls.Indicator>
                                </Controls.Checkbox>
                                <Controls.Label htmlFor={id}>
                                    {id}
                                </Controls.Label>
                            </Controls.CheckboxRow>
                        ))}
                    </Controls.Group>
                </Controls.CollapsibleContent>
            </Controls.CollapsibleRoot>
        </Controls.Panel>
    )
}
