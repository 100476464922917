import { Issue as IssueType } from '../../data/api'
import { DL, DT, DD } from '../../ds/DescriptionList'

interface Props {
    issueDoc: IssueType
    size: 'md' | 'sm' | 'xs'
    layout?: 'horizontal' | 'vertical'
}

const priorityToImportance = {
    1: 'Low',
    2: 'Medium low',
    3: 'Medium',
    4: 'Medium high',
    5: 'High',
}

export function IssueDescription({ issueDoc, size, layout }: Props) {
    return (
        <DL layout={layout}>
            <DT size={size}>Name</DT>
            <DD size={size}>
                Issue {issueDoc.issueNumber} <br />
                {issueDoc.name}
            </DD>
            <DT size={size}>Importance</DT>
            {/* @ts-ignore */}
            <DD size={size}>{priorityToImportance[issueDoc.priority]}</DD>
            <DT size={size}>Description</DT>
            <DD size={size}>{issueDoc.description}</DD>
            <DT size={size}>Type</DT>
            <DD size={size}>{issueDoc.type}</DD>
            <DT size={size}>Section</DT>
            <DD size={size}>{issueDoc.issueSection}</DD>
        </DL>
    )
}
