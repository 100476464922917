import { styled } from '../stitches.config'
import { IssueIcon } from '../ds/Node'
import { GroupTitle } from '../ds/Controls'

const LegendCont = styled('div', {
    width: 170,
    height: 230,
    position: 'fixed',
    right: 10,
    top: 10,
    padding: 10,
    backgroundColor: 'white',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    fontSize: '$2',
    fontWeight: 'bold',
    zIndex: '$3',

    border: '2px solid #f3f3f3',
    opacity: '0.7',

    // opacity: 0.5,
})

const LegendItem = styled('div', {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    // backgroundColor: 'cornflowerblue'
})

const LegendColourBox = styled('div', {
    width: 20,
    height: 20,

    flex: 'start',
    borderWidth: 2,

    variants: {
        type: {
            dataSource: {
                backgroundColor: '$blue300',
                borderColor: '$blue400',
            },
            system: {
                backgroundColor: '$pink300',
                borderColor: '$pink400',
            },
            kpi: {
                backgroundColor: '$orange300',
                borderColor: '$orange400',
            },
            report: {
                backgroundColor: '$green300',
                borderColor: '$green400',
            },
            analyticalOutput: {
                backgroundColor: '$teal300',
                borderColor: '$teal400',
            },
            issue: {
                backgroundColor: '$red700',
                borderColor: '$red800',
            },
        },

        shape: {
            rect: {
                borderRadius: 0,
            },
            circle: {
                borderRadius: '$round',
            },
        },
    },
})

const LegendSpacer = styled('div', {
    width: '100%',
    height: 1,
    borderTop: '1px solid #f3f3f3',
})

const LegendLabel = styled('div', {
    flex: 'end',
    fontSize: '$1',
    paddingLeft: 10,
    fontWeight: 'normal',

    display: 'flex',
    alignItems: 'center',
})

const items = [
    { id: 'dataSource', name: 'Data Source' },
    { id: 'system', name: 'System / Tool' },
    { id: 'kpi', name: 'KPI / Metric' },
    { id: 'report', name: 'Report / Dashboard' },
    { id: 'analyticalOutput', name: 'Analytical Output' },
    { id: 'issue', name: 'Issue' },
] as const

export function Legend({ forForceVis }: { forForceVis?: boolean }) {
    return (
        <LegendCont>
            <GroupTitle>Legend</GroupTitle>
            {items
                .filter((d) => (forForceVis ? true : d.id !== 'issue'))
                .map((item) => {
                    return (
                        <LegendItem key={item.id}>
                            <LegendColourBox
                                type={item.id}
                                shape={forForceVis ? 'circle' : 'rect'}
                            />
                            <LegendLabel>{item.name}</LegendLabel>
                        </LegendItem>
                    )
                })}
            {!forForceVis && (
                <>
                    <LegendSpacer />
                    <LegendItem>
                        <IssueIcon size={'legend'} />

                        <LegendLabel>Issue</LegendLabel>
                    </LegendItem>
                </>
            )}
        </LegendCont>
    )
}
