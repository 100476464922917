import React from 'react'
import {
    forceCenter,
    forceLink,
    forceManyBody,
    forceSimulation,
    // @ts-ignore
} from 'd3-force-3d'
import { Simulation } from 'd3-force'
import {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { ForceNode, ForceLink, useForceStore } from '../data/forceStore'
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { BufferAttribute, DoubleSide, InstancedMesh, Object3D } from 'three'
import { EcoNode } from '../data/api'
import { config } from '../stitches.config'
import chroma from 'chroma-js'
import { scaleSqrt } from '@visx/scale'
import { extent } from 'd3-array'
import { useDataStore } from '../data/store'

const tc = config.theme.colors

const colors: Record<
    EcoNode['_type'] | 'issue',
    [number, number, number, number]
> = {
    dataSource: chroma('hsl(206,100%,50.0%)').gl(),
    kpi: chroma(tc.orange800).gl(),
    system: chroma(tc.pink800).gl(),
    report: chroma('hsl(145,62%,41.0%)').gl(),
    analyticalOutput: chroma('hsl(173,79%,36.7%)').gl(),
    issue: chroma(tc.red900).gl(),
}

const tempObject = new Object3D()

const setDialogSelector = (s: any) => s.setDialogDocumentID

interface INP {
    nodes: ForceNode[]
    nodesRef: MutableRefObject<ForceNode[]>
    onClick?: (id: number) => void
}

function InstancedNodes({ nodes, nodesRef }: INP) {
    const count = nodes.length
    const meshRef = useRef<InstancedMesh>(null)
    const setDialogDocumentID = useDataStore(setDialogSelector)
    const [hovered, setHovered] = useState<number>()
    const setHoveredDoc = useForceStore((s) => s.setHoveredDoc)

    const minRadius = 2
    const maxRadius = 10

    const radiusScale = useMemo(
        () =>
            scaleSqrt({
                domain: extent(nodes, (d) => d.edgeCount) as [number, number],
                range: [minRadius, maxRadius],
            }),
        [minRadius, maxRadius, nodes],
    )

    const colorArray = useMemo(
        () =>
            Float32Array.from(
                nodes.flatMap((d) => {
                    let c = colors[d.data._type]

                    return c ? [c[0], c[1], c[2]] : [0, 0, 0]
                }),
            ),
        [nodes],
    )

    useFrame(({ clock: { elapsedTime } }) => {
        if (meshRef.current && nodesRef.current) {
            for (let i = 0; i < count; i++) {
                let s = radiusScale(nodes[i].edgeCount)
                if (i === hovered) {
                    s *= 2
                }
                let { x = 0, y = 0, z = 0 } = nodesRef.current[i]

                tempObject.position.set(x, y, z)
                tempObject.scale.set(s, s, s)
                tempObject.rotation.set(0, elapsedTime / 2, 0)

                tempObject.updateMatrix()
                meshRef.current.setMatrixAt(i, tempObject.matrix)
            }
            meshRef.current.instanceMatrix.needsUpdate = true
        }
    })

    return (
        <instancedMesh
            ref={meshRef}
            args={[null as any, null as any, count]}
            onClick={(e) => {
                if (e.instanceId) {
                    let id = nodes[e.instanceId].id
                    setDialogDocumentID(id)
                }
            }}
            onPointerMove={(e) => {
                if (e.instanceId) {
                    setHovered(e.instanceId)
                    setHoveredDoc(nodes[e.instanceId])
                }
            }}
            onPointerOut={(e) => {
                setHovered(undefined)
                setHoveredDoc(null)
            }}
        >
            <icosahedronBufferGeometry args={[1, 2]}>
                <instancedBufferAttribute
                    attachObject={['attributes', 'color']}
                    args={[colorArray, 3]}
                />
            </icosahedronBufferGeometry>
            <meshPhongMaterial flatShading vertexColors={true} />
        </instancedMesh>
    )
}

function Link({
    count,
    links,
}: {
    count: number
    links: MutableRefObject<ForceLink[]>
}) {
    const ref = useRef<BufferAttribute>(null)
    const initialVerts = useMemo(
        () => new Float32Array(new Array(count * 3 * 2).fill(0)),
        [count],
    )

    useFrame(() => {
        if (!ref.current) return

        for (let i = 0; i < count; i++) {
            let link = links.current[i]
            if (link && link.source && link.target) {
                let source: ForceNode = link.source as any
                let target: ForceNode = link.target as any
                ref.current.setXYZ(
                    i * 2,
                    source.x || 0,
                    source.y || 0,
                    source.z || 0,
                )
                ref.current.setXYZ(
                    i * 2 + 1,
                    target.x || 0,
                    target.y || 0,
                    target.z || 0,
                )
            }
        }
        ref.current.needsUpdate = true
    })

    return (
        <lineSegments>
            <bufferGeometry>
                <bufferAttribute
                    ref={ref}
                    attachObject={['attributes', 'position']}
                    count={count * 2}
                    itemSize={3}
                    array={initialVerts}
                />
            </bufferGeometry>
            <lineBasicMaterial color="#bbb" side={DoubleSide} />
        </lineSegments>
    )
}
function IssuesVisWithBounds() {
    const _nodes = useForceStore((s) => s.nodes)
    const _links = useForceStore((s) => s.links)
    const nodes = useRef<ForceNode[]>([])
    const links = useRef<ForceLink[]>([])

    const strength = -20

    const [simulation, setSimulation] =
        useState<Simulation<ForceNode, ForceLink>>()

    const onNodeClick = useCallback(
        (index: number) => {
            console.log(_nodes[index].data)
        },
        [_nodes],
    )

    useEffect(() => {
        nodes.current = _nodes.map((d) => ({ ...d }))
        links.current = _links.map((d) => ({ ...d }))
        const sim = forceSimulation<ForceNode, ForceLink>(nodes.current, 3)
            .force(
                'link',
                forceLink<ForceNode, ForceLink>(links.current).id(
                    (d: ForceNode) => d.id,
                ),
            )
            .force('charge', forceManyBody().strength(strength))
            .force('center', forceCenter(0, 0, 0))

        setSimulation(sim)
    }, [_nodes, _links, setSimulation, strength])

    return (
        <group>
            {simulation && (
                <InstancedNodes
                    nodes={_nodes}
                    nodesRef={nodes}
                    onClick={onNodeClick}
                />
            )}
            {simulation && <Link count={_links.length} links={links} />}
        </group>
    )
}

export function ForceVis() {
    const ready = useForceStore((s) => s.ready)

    return (
        <Canvas
            dpr={[1, 2]}
            camera={{
                position: [0, 0, 750],
                fov: 50,
                near: 1,
                far: 10000,
            }}
        >
            <ambientLight intensity={0.2} />
            <directionalLight />
            <OrbitControls />
            {ready && <IssuesVisWithBounds />}
        </Canvas>
    )
}
