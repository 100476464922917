import { useDataStore } from '../data/store'
import * as Dialog from '../ds/DocDialog'
import { DocumentDetails } from './DocumentDetails'

export function DocModal() {
    const docID = useDataStore((s) => s.dialogDocumentID)
    const setDocID = useDataStore((s) => s.setDialogDocumentID)

    const open = Boolean(docID)

    const onOpenChange = (val: boolean) => {
        if (!val) {
            setDocID(null)
        }
    }

    return (
        <Dialog.Root open={open} onOpenChange={onOpenChange}>
            <Dialog.Overlay></Dialog.Overlay>
            <Dialog.Content>
                {docID && <DocumentDetails id={docID} />}
                <Dialog.Close>Close</Dialog.Close>
            </Dialog.Content>
        </Dialog.Root>
    )
}
