import { styled } from '../stitches.config'

export const FullScreen = styled('div', {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
})

export const AbsoluteFill = styled('div', {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
})
