import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { styled } from '../stitches.config'

export const Box = styled('div', {
    p: '$1',
    position: 'relative',
    borderStyle: 'solid',
    borderWidth: '4px',
    width: '240px',
    minHeight: '80px',

    variants: {
        type: {
            dataSource: {
                backgroundColor: '$blue200',
                borderColor: '$blue300',
            },
            system: {
                backgroundColor: '$pink200',
                borderColor: '$pink300',
            },
            kpi: {
                backgroundColor: '$orange200',
                borderColor: '$orange300',
            },
            report: {
                backgroundColor: '$green200',
                borderColor: '$green300',
            },
            analyticalOutput: {
                backgroundColor: '$teal200',
                borderColor: '$teal300',
            },
        },

        isTBD: {
            true: {
                borderStyle: 'dashed',
            },
        },

        isSelected: {
            true: {
                transform: 'scale(1.2)',
            },
        },

        isIssueSource: {
            true: {
                transform: 'scale(1.2)',
                borderColor: '$red800',
            },
        },

        isIssueConnected: {
            true: {
                transform: 'scale(1.2)',
                borderColor: '$orange700',
            },
        },

        fadeout: {
            true: {
                opacity: 0.1,
            },
            false: {
                opacity: 1,
            },
        },

        scale: {
            md: {
                transform: 'scale(1)',
            },
            lg: {
                transform: 'scale(1.5)',
            },
            xl: {
                transform: 'scale(2.0)',
            },
        },
    },

    compoundVariants: [
        {
            isSelected: true,
            type: 'dataSource',
            css: {
                borderColor: '$blue600',
            },
        },
        {
            isSelected: true,
            type: 'system',
            css: {
                borderColor: '$pink600',
            },
        },
        {
            isSelected: true,
            type: 'kpi',
            css: {
                borderColor: '$orange600',
            },
        },
        {
            isSelected: true,
            type: 'report',
            css: {
                borderColor: '$green600',
            },
        },
        {
            isSelected: true,
            type: 'analyticalOutput',
            css: {
                borderColor: '$teal600',
            },
        },
        {
            isSelected: true,
            isIssueSource: true,
            isIssueConnected: true,
            css: {
                transform: 'scale(1.2)',
                borderColor: '$red800',
            },
        },
        {
            isSelected: true,
            isIssueConnected: true,
            css: {
                transform: 'scale(1.2)',
                borderColor: '$orange700',
            },
        },
        {
            isSelected: true,
            isIssueSource: true,
            type: 'dataSource',
            css: {
                borderColor: '$red800',
            },
        },
        {
            isSelected: true,
            isIssueSource: true,
            type: 'system',
            css: {
                borderColor: '$red800',
            },
        },
        {
            isSelected: true,
            isIssueSource: true,
            type: 'kpi',
            css: {
                borderColor: '$red800',
            },
        },
        {
            isSelected: true,
            isIssueSource: true,
            type: 'report',
            css: {
                borderColor: '$red800',
            },
        },
        {
            isSelected: true,
            isIssueSource: true,
            type: 'analyticalOutput',
            css: {
                borderColor: '$red800',
            },
        },
        {
            isTBD: true,
            type: 'kpi',
            css: {
                backgroundColor: 'white',
            },
        },
        {
            isTBD: true,
            type: 'dataSource',
            css: {
                backgroundColor: 'white',
            },
        },
        {
            isTBD: true,
            type: 'system',
            css: {
                backgroundColor: 'white',
            },
        },
        {
            isTBD: true,
            type: 'report',
            css: {
                backgroundColor: 'white',
            },
        },
        {
            isTBD: true,
            type: 'analyticalOutput',
            css: {
                backgroundColor: 'white',
            },
        },
    ],
})

export const TypeLabel = styled('div', {
    fontFamily: '$inter',
    fontSize: '$1',
    fontWeight: 700,
    textTransform: 'capitalize',

    variants: {
        type: {
            dataSource: {
                color: '$blue600',
            },
            system: {
                color: '$pink600',
            },
            kpi: {
                color: '$orange600',
            },
            report: {
                color: '$green600',
            },
            analyticalOutput: {
                color: '$teal600',
            },
        },
    },
})

export const Name = styled('div', {
    fontFamily: '$inter',
    fontSize: '$4',
})

export const IssueIcon = styled(ExclamationTriangleIcon, {
    borderRadius: '$round',
    boxSizing: 'content-box',
    overflow: 'visible',

    variants: {
        size: {
            sm: {
                size: 20,
                padding: 5,
            },
            md: {
                size: 40,
                padding: 10,
            },
            lg: {
                size: 60,
                padding: 15,
            },
            legend: {
                size: 15,
                padding: 4,
            },
        },
        priority: {
            1: {
                backgroundColor: '$red500',
                color: '$red100',
            },
            2: {
                backgroundColor: '$red600',
                color: '$red100',
            },
            3: {
                backgroundColor: '$red700',
                color: '$red100',
            },
            4: {
                backgroundColor: '$red800',
                color: '$red100',
            },
            5: {
                backgroundColor: '$red900',
                color: '$red100',
            },
        },
        isOpaque: {
            true: {},
            false: {
                backgroundColor: '$gray200',
                color: '$gray800',
            },
        },
    },
    defaultVariants: {
        size: 'sm',
        priority: '5',
    },
    compoundVariants: [
        {
            isOpaque: false,
            priority: '1',
            css: { backgroundColor: '$gray200', color: '$gray800' },
        },
        {
            isOpaque: false,
            priority: '2',
            css: { backgroundColor: '$gray200', color: '$gray800' },
        },
        {
            isOpaque: false,
            priority: '3',
            css: { backgroundColor: '$gray200', color: '$gray800' },
        },
        {
            isOpaque: false,
            priority: '4',
            css: { backgroundColor: '$gray200', color: '$gray800' },
        },
        {
            isOpaque: false,
            priority: '5',
            css: { backgroundColor: '$gray200', color: '$gray800' },
        },
    ],
})

export const IssueGroup = styled('div', {
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translateY(-50%)',
})
