import React from 'react'
import { useDataStore } from '../data/store'
import { FlowVis } from './FlowVis'
import { FullScreen } from '../ds/Position'

export function Vis() {
    const ready = useDataStore((s) => s.ready)

    return <FullScreen>{ready && <FlowVis />}</FullScreen>
}
