import { Link, useRoute } from 'wouter'
import { styled } from '../stitches.config'

const NavBarCont = styled('div', {
    width: '100%',
    height: 40,
    flexShrink: 0,

    display: 'flex',

    fontSize: '$2',
    justifyContent: 'space-between',

    margin: '5px 0 5px 0',

    borderTop: '1px solid $gray600',
})

const NavBarItem = styled('div', {
    height: '100%',
    flex: 1,
    borderRight: '1px solid $gray600',

    borderBottom: '1px solid $gray600',
    userSelect: 'none',

    fontSize: '$2',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    variants: {
        position: {
            right: {
                borderRight: 'none',
            },
        },
        active: {
            true: {
                borderBottom: 'none',
                fontWeight: 'bold',
            },
            false: {
                borderBottom: '1px solid $gray600',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                boxShadow: 'inset 0px -4px  6px -6px rgba(0, 0, 0, 0.3)',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
            },
        },
    },
})

const ActiveLink = (props) => {
    const [isActive] = useRoute(props.href)
    return (
        <Link {...props}>
            <NavBarItem
                position={props.pos}
                active={isActive ? 'true' : 'false'}
            >
                {props.label}
            </NavBarItem>
        </Link>
    )
}

export function NavBar() {
    return (
        <NavBarCont>
            <ActiveLink href="/" label={'Ecosystem'}></ActiveLink>
            <ActiveLink href="/force" label={'Force Graph'}></ActiveLink>
            <ActiveLink
                href="/issues"
                label={'Issues'}
                pos={'right'}
            ></ActiveLink>
        </NavBarCont>
    )
}
