import create, { State } from 'zustand'
import { SanityDocument } from '@sanity/client'
import { Issue } from '../data/api'
// import { client } from '../lib/sanity'
import uniq from 'lodash.uniq'

export interface BubbleIssue extends Issue {
    id: string
    section: string
}

export interface Check {
    name: string
    checked: boolean
}

export interface Control {
    name: 'Sections' | 'Types'
    data: Check[]
}

export interface BubbleStore extends State {
    ready: boolean
    issues: SanityDocument<BubbleIssue>[]
    priority: number
    controls: Control[]
    changeControls: (s: 'Sections' | 'Type', n: string) => void
    setPriority: (n: number) => void
    fetch: () => void
}

export const useBubbleStore = create<BubbleStore>((set) => ({
    ready: false,
    issues: [],
    controls: [],
    async fetch() {
        // const issues = await client.fetch<SanityDocument<Issue>[]>(
        //     `*[_type == "issue"]`,
        // )
        const response = await fetch('/data/bubble-data.json')
        const issues = (await response.json()) as SanityDocument<Issue>[]

        const sections: Check[] = [
            {
                name: 'All',
                checked: true,
            },
            ...uniq(issues.map((d) => d.issueSection)).map((s) => ({
                name: s,
                checked: false,
            })),
        ]
        const types: Check[] = [
            {
                name: 'All',
                checked: true,
            },
            ...uniq(issues.map((d) => d.type)).map((s) => ({
                name: s,
                checked: false,
            })),
        ]

        const controls: Control[] = [
            { name: 'Sections', data: sections },
            { name: 'Types', data: types },
        ]

        set({
            ready: true,
            controls,
            issues: issues.map((d) => ({
                ...d,
                id: d._id,
                section: d.issueSection,
            })),
        })
    },
    priority: 1,
    setPriority(n) {
        set({ priority: n })
    },
    changeControls(s, n) {
        set((state) => {
            let new_cont = state.controls.splice(0)
            let cat = new_cont.findIndex((d) => d.name === s)

            //Check if All has been selected
            if (n === 'All') {
                new_cont[cat].data[0].checked = new_cont[cat].data[0].checked
                    ? false
                    : true
                for (let i = 1; i < new_cont[cat].data.length; i++) {
                    new_cont[cat].data[i].checked = false
                }
            } else {
                new_cont[cat].data[0].checked = false
            }

            for (let i = 1; i < new_cont[cat].data.length; i++) {
                if (new_cont[cat].data[i].name === n) {
                    new_cont[cat].data[i].checked = new_cont[cat].data[i]
                        .checked
                        ? false
                        : true
                }
            }
            return {
                ...state,
                controls: new_cont,
            }
        })
    },
}))

/**
 *     let new_cont = controls.splice(0)

        let cat = new_cont.findIndex((d) => d.name === s)

        //Check if All has been selected
        if (n === 'All') {
            new_cont[cat].data[0].checked = new_cont[cat].data[0].checked
                ? false
                : true
            for (let i = 1; i < new_cont[cat].data.length; i++) {
                new_cont[cat].data[i].checked = false
            }
        } else {
            new_cont[cat].data[0].checked = false
        }

        for (let i = 1; i < new_cont[cat].data.length; i++) {
            if (new_cont[cat].data[i].name === n) {
                new_cont[cat].data[i].checked = new_cont[cat].data[i].checked
                    ? false
                    : true
            }
        }
        setControls(new_cont)
 */
