import { KPI, Report, AnalyticalOutput, System, DataSource, Issue } from './api'
import create, { State } from 'zustand'
// import { client } from '../lib/sanity'
import { SimulationLinkDatum, SimulationNodeDatum } from 'd3-force'
import { index } from 'd3-array'
import { MutableRefObject } from 'react'

const query = `
{
    "things": *[ _type in ["kpi", "report", "system", "dataSource", "analyticalOutput"]]{
        "id": _id,
        "data": {...},
        "out": *[_type == "edge" && source._ref == ^._id].target._ref,
        "in": *[_type == "edge" && target._ref == ^._id].source._ref,
    },
    "issues": *[_type == "issue"]{
        "id": _id,
        "data": {...},
        "nodeLinks": *[_type in ["kpi", "report", "system", "dataSource", "analyticalOutput"] && references(^._id)]._id,
        "edgeLinks": *[_type == "edge" && references(^._id)]{ "source": source._ref, "target": target._ref }
    },
}
`
interface Thing {
    id: string
    data: KPI | Report | AnalyticalOutput | System | DataSource
    out: string[]
    in: string[]
}

interface Link {
    source: string
    target: string
}

interface MyIssue {
    id: string
    data: Issue
    nodeLinks: string[]
    edgeLinks: Link[]
}

export interface ForceNode extends SimulationNodeDatum {
    id: string
    data: KPI | Report | AnalyticalOutput | System | DataSource | Issue
    edgeCount: number

    // Add in for 3d sim
    z?: number
    vz?: number
}

export interface ForceLink extends SimulationLinkDatum<ForceNode> {
    source: string
    target: string
    isIssueLink?: boolean
}

export interface ForceData {
    things: Thing[]
    issues: MyIssue[]
}

export interface ForceStore extends State {
    ready: boolean
    things: Thing[]
    issues: MyIssue[]
    nodes: ForceNode[]
    links: ForceLink[]
    nodesRef: MutableRefObject<ForceNode[]> | null
    fetch: () => void
    count: (id: string) => number

    hovered: number | null
    setHovered: (b: number | null) => void

    hoveredDoc: ForceNode | null
    setHoveredDoc: (v: ForceNode | null) => void
}

export const useForceStore = create<ForceStore>((set, get) => ({
    ready: false,
    things: [],
    issues: [],
    nodes: [],
    links: [],
    nodesRef: null,
    count: (id) => 0,
    async fetch() {
        // const { things, issues } = await client.fetch<ForceData>(query)

        const response = await fetch('/data/force-data.json')
        const { things, issues } = (await response.json()) as ForceData

        const thingNodes: ForceNode[] = things.map((d) => ({
            id: d.id,
            data: d.data,
            edgeCount:
                d.in.length +
                d.out.length +
                (d.data?.linkedIssues?.length || 0),
        }))

        const issueNodes: ForceNode[] = issues.map((d) => ({
            id: d.id,
            data: d.data,
            edgeCount: d.nodeLinks.length + d.edgeLinks.length * 2,
        }))

        const nodes: ForceNode[] = thingNodes.concat(issueNodes)

        const thingLinks: ForceLink[] = things.flatMap((d) =>
            d.out.map((target) => ({
                source: d.id,
                target,
            })),
        )

        const issuesLinks: ForceLink[] = issues.flatMap((d) =>
            d.nodeLinks.map((target) => ({
                source: d.id,
                target,
                isIssueLink: true,
            })),
        )

        const links: ForceLink[] = thingLinks.concat(issuesLinks)

        const nodesById = index(nodes, (d) => d.id)

        function count(id: string) {
            return nodesById.has(id) ? nodesById.get(id)!.edgeCount : 0
        }

        set({
            things,
            issues,
            nodes,
            links,
            count,
            ready: true,
        })
    },
    hovered: null,
    setHovered(v) {
        set({ hovered: v })
    },

    hoveredDoc: null,
    setHoveredDoc(v) {
        set({ hoveredDoc: v })
    },
}))
