import { styled } from '../stitches.config'
import { ForceNode, useForceStore } from '../data/forceStore'
import * as Controls from '../ds/Controls'
import { EcoNode } from '../data/api'
import { DD, DL, DT } from '../ds/DescriptionList'

const Type = styled('h2', {
    fontFamily: '$inter',
    fontWeight: 700,
    fontSize: '$2',

    variants: {
        type: {
            dataSource: {
                color: '$blue600',
            },
            system: {
                color: '$pink600',
            },
            kpi: {
                color: '$orange600',
            },
            report: {
                color: '$green600',
            },
            analyticalOutput: {
                color: '$teal600',
            },
            issue: {
                color: '$red900',
            },
        },
    },
})

const typeTitle: Record<EcoNode['_type'] | 'issue', string> = {
    kpi: 'KPI / Metric',
    dataSource: 'Data Source',
    report: 'Report / Dashboard',
    analyticalOutput: 'Analytical Output',
    system: 'System / Tool',
    issue: 'Issue',
}

export function ForceControls() {
    const hoveredDoc = useForceStore((s) => s.hoveredDoc)

    return (
        <Controls.Section>
            <Controls.Title>3D Force Directed View</Controls.Title>
            <Controls.Description>
                This is a 3D force directed view of the ecosystem.
            </Controls.Description>
            <Controls.Description>
                Force directed visualisations use a physics based simulation
                that often result in "well seperated" layouts. In this
                visualisation, all nodes try to repel from each other, while
                edges will attract connected nodes together. All nodes and edges
                from the ecosystem have been added to this visualisation, with
                the addition of Issues as nodes, and edges connecting other
                nodes to relevant issues.
            </Controls.Description>
            <Controls.Description>
                Click on nodes for more details on each node.
            </Controls.Description>
            {hoveredDoc && <HoveredDoc node={hoveredDoc} />}
        </Controls.Section>
    )
}

function HoveredDoc({ node }: { node: ForceNode }) {
    const { data, edgeCount } = node

    return (
        <Controls.Group>
            {/* <Controls.GroupTitle>Hovered Node</Controls.GroupTitle> */}
            <Type type={data._type}>{typeTitle[data._type]}</Type>
            <DL>
                <DT size="sm">Name</DT>
                <DD>{data.name}</DD>
                <DT size="sm">Edge Count</DT>
                <DD>{edgeCount}</DD>
            </DL>
        </Controls.Group>
    )
}
