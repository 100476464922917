import ReactFlow, { Background, MiniMap } from 'react-flow-renderer'
import { minimapNodeColor, useDataStore } from '../data/store'
import { CustomNode } from './CustomNode'
import { CustomEdge } from './CustomEdge'
import { Legend } from './Legend'

export function FlowVis() {
    const elements = useDataStore((s) => s.elements)
    const onSelectionChange = useDataStore((s) => s.onSelectionChange)
    const setDialogDocumentID = useDataStore((s) => s.setDialogDocumentID)

    return (
        <>
            <ReactFlow
                elements={elements}
                edgeTypes={{
                    custom: CustomEdge,
                }}
                nodeTypes={{
                    custom: CustomNode,
                }}
                onLoad={(instance) => instance.fitView()}
                minZoom={0.05}
                snapToGrid
                onlyRenderVisibleElements
                onNodeDoubleClick={(e, node) => {
                    e.stopPropagation()
                    console.log(node)
                    setDialogDocumentID(node.data._id)
                }}
                onSelectionChange={onSelectionChange}
            >
                {/* <Controls /> */}
                <Background />
                <MiniMap nodeColor={minimapNodeColor} />
            </ReactFlow>
            <Legend />
        </>
    )
}
