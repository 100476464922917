import { SanityDocument } from '@sanity/client'
import { EcoNode, SubSection, Section, Branch, Ref, Issue } from '../data/api'
import { useDataStore } from '../data/store'
import { styled } from '../stitches.config'
import * as RadixSeparator from '@radix-ui/react-separator'
import { Fragment } from 'react'
// @ts-ignore
import SanityBlockRenderer from '@sanity/block-content-to-react'
import { DL, DD, DT } from '../ds/DescriptionList'
import { IssueDescription } from './descriptions/Issue'

const Separator = styled(RadixSeparator.Root, {
    backgroundColor: '$gray300',
    height: 1,
    margin: '20px 0',
})

const Type = styled('h2', {
    fontFamily: '$inter',
    fontWeight: 700,
    fontSize: '$2',

    variants: {
        type: {
            dataSource: {
                color: '$blue600',
            },
            system: {
                color: '$pink600',
            },
            kpi: {
                color: '$orange600',
            },
            report: {
                color: '$green600',
            },
            analyticalOutput: {
                color: '$teal600',
            },
            issue: {
                color: '$red900',
            },
        },
    },
})

const Name = styled('h1', {
    fontFamily: '$inter',
    fontWeight: 500,
    fontSize: '$5',
})

interface Props {
    id: string
}

const Prose = styled('div', {
    fontFamily: '$inter',
    fontSize: '$3',

    variants: {
        size: {
            xs: {
                fontSize: '$1',
            },
            sm: {
                fontSize: '$2',
            },
        },
    },
})

const Box = styled('div', {})

function LinkedIssue({ id }: Props) {
    const data = useDataStore((s) => s.docs[id]) as SanityDocument<Issue>
    return <IssueDescription issueDoc={data} size="xs" />
}

function LinkedIssues({ refs }: { refs: Ref[] }) {
    return (
        <>
            <Separator />
            <DL>
                <DT>Issues</DT>
                <DD>
                    <Box css={{ display: 'grid', gap: '$2' }}>
                        {refs.map((d, i) => (
                            <Fragment key={d._ref}>
                                {i !== 0 && <Separator />}
                                <LinkedIssue id={d._ref} />
                            </Fragment>
                        ))}
                    </Box>
                </DD>
            </DL>
        </>
    )
}

function Owners({ ids }: { ids: string[] }) {
    const data = useDataStore((s) => ids.map((id) => s.docs[id])) as Array<
        SanityDocument<Branch | Section | SubSection>
    >

    return (
        <>
            <Separator />
            <DL>
                <DT>Owners</DT>
                <DD>
                    {data
                        .filter((d) => !!d)
                        .map((d) => (
                            <Fragment key={d._id}>
                                {d.name} <br />
                            </Fragment>
                        ))}
                </DD>
            </DL>
        </>
    )
}

function KPIType({ type = 'No Category' }: { type?: string }) {
    return (
        <>
            <Separator />
            <DL>
                <DT>Category</DT>
                <DD>{type}</DD>
            </DL>
        </>
    )
}

function Description({ blocks }: { blocks: any }) {
    return (
        <>
            <Separator />
            <DL>
                <DT>Description</DT>
                <DD>
                    <Prose>
                        <SanityBlockRenderer blocks={blocks} />
                    </Prose>
                </DD>
            </DL>
        </>
    )
}

export function EcoDoc({ doc }: { doc: EcoNode }) {
    return (
        <>
            <Owners
                ids={[
                    doc.owner?._ref || 'Unowned',
                    ...(doc.otherOwners?.map((d) => d._ref) || []),
                ]}
            />
            {doc._type === 'kpi' && <KPIType type={doc.type} />}
            {doc.description && <Description blocks={doc.description} />}
            {doc.linkedIssues && doc.linkedIssues.length > 0 && (
                <LinkedIssues refs={doc.linkedIssues} />
            )}
        </>
    )
}

const typeTitle: Record<EcoNode['_type'] | 'issue', string> = {
    kpi: 'KPI / Metric',
    dataSource: 'Data Source',
    report: 'Report / Dashboard',
    analyticalOutput: 'Analytical Output',
    system: 'System / Tool',
    issue: 'Issue',
}

export function DocumentDetails({ id }: Props) {
    const doc = useDataStore((s) => s.docs[id]) as
        | SanityDocument<Issue>
        | EcoNode

    return (
        <>
            <Type type={doc._type}>{typeTitle[doc._type]}</Type>
            <Name>{doc.name}</Name>
            {doc._type === 'issue' ? (
                <IssueDescription size="md" issueDoc={doc} />
            ) : (
                <EcoDoc doc={doc} />
            )}
            <Box css={{ height: 40 }} />
        </>
    )
}
