import { css, styled } from '../stitches.config'
import { Logo } from '@smash-delta/logo'
import TransportLogo from '../components/TransportLogo'
import * as RadixSeparator from '@radix-ui/react-separator'
import { Switch, Route } from 'wouter'
import { EcosystemControls } from './EcosystemControls'
import { NavBar } from './NavBar'
import { Controls as BubbleControls } from '../issuesVis/Controls'
import { ForceControls } from '../3d/ForceControls'
import { Login } from './Login'
import { Authorisation } from './Authorisation'

const Panel = styled('div', {
    position: 'fixed',
    zIndex: '$1',
    top: 0,
    left: 0,
    bottom: 0,
    width: 400,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',

    borderRight: '2px solid',
    borderColor: '$gray200',

    display: 'flex',
    flexDirection: 'column',
})

const ControlsGroup = styled('div', {
    flexGrow: 1,
    overflowY: 'auto',
    paddingTop: '$3',
})

const PanelSeparator = styled(RadixSeparator.Root, {
    backgroundColor: '#eae4e4',
    height: 1,
    my: 20,
})

const LogoSeperator = styled(RadixSeparator.Root, {
    backgroundColor: '#eae4e4',
    width: 1,
    height: 35,
    mx: 20,
})

const sdLogo = css({
    width: 'auto',
    height: 35,
    color: '#121212',
    flexShrink: 0,
    flexGrow: 0,
})

const tfnswLogo = css({
    width: 'auto',
    height: 40,
    flexShrink: 0,
    flexGrow: 0,
})

const TitleGroup = styled('header', {
    px: '$2',
    pt: '$4',
})

const Title = styled('h1', {
    fontFamily: '$heebo',
    fontWeight: 900,
    fontSize: '$6',
    textTransform: 'uppercase',
    letterSpacing: 0.3,
    color: '#3353FF',
})

const SubTitle = styled('h3', {
    fontFamily: '$heebo',
    fontWeight: 900,
    fontSize: '$4',
    textTransform: 'uppercase',
    letterSpacing: 0.1,
    color: '$gray800',
})

const LogoGroup = styled('div', {
    display: 'flex',
    alignItems: 'center',
    px: '$2',
    pb: '$4',
})

export function SidePanel() {
    return (
        <Panel>
            <TitleGroup>
                <Title>N&A - Operational Intelligence Ecosystem</Title>
                <SubTitle>Prototype</SubTitle>
            </TitleGroup>

            <NavBar />

            <ControlsGroup>
                <Authorisation fallback={<Login />}>
                    <Switch>
                        <Route path="/">
                            <EcosystemControls />
                        </Route>
                        <Route path="/force">
                            <ForceControls />
                        </Route>
                        <Route path="/issues">
                            <BubbleControls />
                        </Route>
                    </Switch>
                </Authorisation>
            </ControlsGroup>

            <PanelSeparator />

            <LogoGroup>
                <Logo className={sdLogo()} />
                <LogoSeperator orientation="vertical" />
                <TransportLogo className={tfnswLogo()} />
            </LogoGroup>
        </Panel>
    )
}
