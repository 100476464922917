import './preflight.css'

import { render } from 'react-dom'
import { enableMapSet } from 'immer'
import App from './App'

enableMapSet()

const rootElement = document.getElementById('root')
render(<App />, rootElement)
