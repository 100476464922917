import { styled } from '../stitches.config'
import * as RadixCheckbox from '@radix-ui/react-checkbox'
import * as RadixLabel from '@radix-ui/react-label'
import { CheckIcon } from '../issuesVis/ControlCheckbox'
import * as RadixMenu from '@radix-ui/react-dropdown-menu'
import * as RadixSeparator from '@radix-ui/react-separator'
import * as RadixCollapsible from '@radix-ui/react-collapsible'

export const Checkbox = styled(RadixCheckbox.Root, {
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    boxShadow: 'inset 0 0 0 1px gainsboro',
    width: 25,
    height: 25,
    borderRadius: 5,
    margin: 5,

    flex: 'start',

    '&:focus': {
        border: 'none',
        boxShadow: 'none',
    },
})

export const CheckboxRow = styled('div', {
    display: 'flex',
    alignItems: 'center',
})

export const Label = styled(RadixLabel.Root, {
    marginLeft: '$2',
    fontFamily: '$inter',
    fontSize: '$1',
})

export const Title = styled('h2', {
    fontFamily: '$heebo',
    fontSize: '$5',
    fontWeight: 900,
    textTransform: 'uppercase',
})

export const CollapsibleRoot = styled(RadixCollapsible.Root, {
    display: 'grid',
    gap: '$2',
    px: '$2',
})

export const CollapsibleTitle = styled(RadixCollapsible.Button, {
    fontFamily: '$heebo',
    fontSize: '$5',
    fontWeight: 900,
    textTransform: 'uppercase',
})

export const CollapsibleContent = styled(RadixCollapsible.Content, {
    display: 'grid',
    gap: '$2',
})

export const Panel = styled('div', {})

export const Section = styled('div', {
    display: 'grid',
    gap: '$2',
    px: '$2',
})
export const Group = styled('div', {
    display: 'grid',
    gap: '$1',
})

export const GroupTitle = styled('h3', {
    fontFamily: '$inter',
    fontSize: '$3',
    fontWeight: 600,
})

export const GroupSeparator = styled(RadixSeparator.Root, {
    height: 1,
    margin: '5px auto',
    width: '80%',
    backgroundColor: '$gray500',
})

export const SectionSeperator = styled(RadixSeparator.Root, {
    height: 1,
    margin: '20px 0',
    backgroundColor: '$gray600',
})

export const Description = styled('p', {
    fontFamily: '$inter',
    fontSize: '$2',
    color: '$gray900',
})

export const Icon = CheckIcon
export const Indicator = RadixCheckbox.Indicator

export const MenuRoot = styled(RadixMenu.Root, {})

export const MenuTrigger = styled(RadixMenu.Trigger, {})

export const MenuContent = styled(RadixMenu.Content, {
    maxHeight: '20vh',
    overflowY: 'scroll',
    width: '100%',
})

export const MenuItem = styled(RadixMenu.Item, {})
