import { ReactNode } from 'react'
import { useUser } from 'reactfire'

interface AuthorisationProps {
    children: ReactNode
    fallback: ReactNode
}

export function Authorisation({ fallback, children }: AuthorisationProps) {
    const { data: user } = useUser()

    if (!user) {
        return <>{fallback}</>
    }

    const email = user.email

    if (email.includes('@smashdelta.com') || email.includes('@transport.nsw')) {
        return <>{children}</>
    }

    return <>{fallback}</>
}
