import { SanityDocument } from '@sanity/client'
import create, { State } from 'zustand'
// import { client } from '../lib/sanity'
import { EcoEdge, EcoNode, Issue } from './api'
import produce from 'immer'
import uniq from 'lodash.uniq'

const issuesQuery = `*[_type == "issue"]{
    ...,
    "nodeLinks": *[_type in ["kpi", "report", "system", "dataSource", "analyticalOutput"] && references(^._id)],
    "edgeLinks": *[_type == "edge" && references(^._id)]
} | order(issueNumber asc)`

export type MyIssue = SanityDocument<
    Issue & {
        nodeLinks: EcoNode[]
        edgeLinks: EcoEdge[]
    }
>
type Issues = MyIssue[]

export interface IssuesStore extends State {
    ready: boolean
    issues: Issues

    // Issue Types
    issueTypes: string[]
    selectedIssueTypes: Set<string>
    toggleIssueType: (type: string) => void

    // Issue Sections
    issueSections: string[]
    selectedIssueSections: Set<string>
    toggleIssueSection: (section: string) => void

    specificIssue: MyIssue | null
    setSpecificIssue: (v: MyIssue | null) => void

    // Fetch
    fetch: () => void
}

export const useIssuesStore = create<IssuesStore>((set) => ({
    ready: false,
    issues: [],
    issueTypes: [],
    selectedIssueTypes: new Set(),
    issueTypesByIssueID: {},
    issueSections: [],
    selectedIssueSections: new Set(),
    async fetch() {
        // const issues = await client.fetch<Issues>(issuesQuery)

        const response = await fetch('/data/issues-data.json')
        const issues = (await response.json()) as Issues

        const issueTypes = uniq(issues.map((d) => d.type))
        const selectedIssueTypes = new Set(issueTypes)

        const issueSections = uniq(issues.map((d) => d.issueSection))
        const selectedIssueSections = new Set(issueSections)

        set({
            ready: true,
            issues,
            issueTypes,
            selectedIssueTypes,
            issueSections,
            selectedIssueSections,
        })
    },
    toggleIssueType(id: string) {
        set(
            produce((state: IssuesStore) => {
                let set = state.selectedIssueTypes
                if (set.has(id)) {
                    set.delete(id)
                } else {
                    set.add(id)
                }
            }),
        )
    },
    toggleIssueSection(id: string) {
        set(
            produce((state: IssuesStore) => {
                let set = state.selectedIssueSections
                if (set.has(id)) {
                    set.delete(id)
                } else {
                    set.add(id)
                }
            }),
        )
    },
    specificIssue: null,
    setSpecificIssue(specificIssue) {
        set({ specificIssue })
    },
}))
