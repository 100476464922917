import { SanityDocument } from '@sanity/client'
import create, { State } from 'zustand'
// import { client } from '../lib/sanity'
import { Branch, Section, SubSection } from './api'
import produce from 'immer'

type SelectedOwnerIDs = Record<string, boolean>

interface OwnerStore extends State {
    ready: boolean
    branches: SanityDocument<Branch>[]
    sections: SanityDocument<Section>[]
    subSections: SanityDocument<SubSection>[]
    fetch: () => void
    selectedOwnerIDs: SelectedOwnerIDs
    toggleOwnerID: (ownerID: string) => void
}

const ownerQuery = `
{
    "branches": *[_type == "branch"],
    "sections": *[_type == "section"],
    "subSections": *[_type == "subSection"]
}
`

interface OwnerQuery {
    branches: SanityDocument<Branch>[]
    sections: SanityDocument<Section>[]
    subSections: SanityDocument<SubSection>[]
}

export const useOwnerStore = create<OwnerStore>((set) => ({
    ready: false,
    branches: [],
    sections: [],
    subSections: [],
    async fetch() {
        // Get all branches, sections & subsections
        // const { branches, sections, subSections } =
        //     await client.fetch<OwnerQuery>(ownerQuery)

        const response = await fetch('/data/owners-data.json')
        const { branches, sections, subSections } =
            (await response.json()) as OwnerQuery

        // Default all to selected
        let selectedOwnerIDs: SelectedOwnerIDs = {
            unowned: true,
        }
        branches.forEach((d) => {
            selectedOwnerIDs[d._id] = true
        })
        sections.forEach((d) => {
            selectedOwnerIDs[d._id] = true
        })
        subSections.forEach((d) => {
            selectedOwnerIDs[d._id] = true
        })

        set({ branches, sections, subSections, selectedOwnerIDs, ready: true })
    },
    selectedOwnerIDs: {
        unowned: true,
    },
    toggleOwnerID(id) {
        set(
            produce((state: OwnerStore) => {
                state.selectedOwnerIDs[id] = !state.selectedOwnerIDs[id]
            }),
        )
    },
}))
