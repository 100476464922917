import { NodeComponentProps } from 'react-flow-renderer'
import { EcoNode, Issue } from '../data/api'
import * as Node from '../ds/Node'
import { Handle, Position } from 'react-flow-renderer'
import { useOwnerStore } from '../data/ownerStore'
import { useCallback, useMemo } from 'react'
import { useDataStore, Store } from '../data/store'
import { SanityDocument } from '@sanity/client'
import { IssueIndicator } from './IssueIndicator'

type IssueDoc = SanityDocument<Issue>
type Props = NodeComponentProps<EcoNode>

const typeTitle: Record<EcoNode['_type'], string> = {
    kpi: 'KPI / Metric',
    dataSource: 'Data Source',
    report: 'Report / Dashboard',
    analyticalOutput: 'Analytical Output',
    system: 'System / Tool',
}

const highlightTBDSelector = (s: Store) => s.highlightTBDs

export function CustomNode({ id, data, selected }: Props) {
    const issueIds = useMemo(
        () => (data.linkedIssues ? data.linkedIssues.map((d) => d._ref) : []),
        [data.linkedIssues],
    )

    const issues = useDataStore(
        useCallback(
            (s) =>
                issueIds
                    .map((id) => s.docs[id] as IssueDoc)
                    .sort((a, b) => b.priority - a.priority),
            [issueIds],
        ),
    )

    const highlightTBDs = useDataStore(highlightTBDSelector)
    const { owner, otherOwners } = data

    const ownerIDs = useMemo(() => {
        let ids = []
        if (owner) {
            ids.push(owner._ref)
        }
        if (otherOwners) {
            otherOwners.forEach(({ _ref }) => ids.push(_ref))
        }
        return ids
    }, [owner, otherOwners])

    const ownerIsSelected = useOwnerStore(
        useCallback(
            (store) => {
                // store.selectedOwnerIDs[ownerID]
                let isSelected = false
                ownerIDs.forEach((id) => {
                    if (store.selectedOwnerIDs[id]) {
                        isSelected = true
                    }
                })
                return isSelected
            },
            [ownerIDs],
        ),
    )
    const isSelected =
        useDataStore(useCallback((s) => s.highlighted.includes(id), [id])) ||
        selected

    const isIssueSource = useDataStore(
        useCallback((s) => s.sourceIssueElements?.includes(id), [id]),
    )
    const isIssueConnected = useDataStore(
        useCallback((s) => s.connectedIssueElements?.includes(id), [id]),
    )

    const fadeout = !ownerIsSelected || (highlightTBDs && !data.isTBD)

    return (
        <Node.Box
            type={data._type}
            fadeout={fadeout}
            isSelected={isSelected}
            isTBD={data.isTBD}
            isIssueSource={isIssueSource}
            isIssueConnected={isIssueConnected}
        >
            <Node.TypeLabel type={data._type}>
                {typeTitle[data._type]}
            </Node.TypeLabel>
            <Node.Name>{data.name}</Node.Name>

            <Node.IssueGroup>
                {issues.map((d) => (
                    <IssueIndicator key={d._id} issue={d} iconSize="sm" />
                ))}
            </Node.IssueGroup>
            <Handle type="source" position={Position.Right} />
            <Handle type="target" position={Position.Left} />
        </Node.Box>
    )
}
