// @ts-nocheck
import { Group } from '@visx/group'
import { Text } from '@visx/text'
import { Pack, hierarchy } from '@visx/hierarchy'

import { motion, AnimatePresence } from 'framer-motion'

import { scaleOrdinal } from '@visx/scale'

import { TitlePopUpCont } from './components'

import * as d3 from 'd3'
import { Control, useBubbleStore } from './bubbleStore'

type dataType = {
    name: string
    description: string
    section: string
    type: string
    priority: number
}

type controlType = {
    name: string
    checked: boolean
}

export type bubbleProps = {
    width: number
    height: number
    data?: dataType[]
    controls?: controlType[]
    priority?: number
}

function getControls(c: controlType[]) {
    let out: string[] = []

    for (let i = 0; i < c.length; i++) {
        if (c[i].checked) {
            out.push(c[i].name)
        }
    }

    console.log(out)
    return out
}

function getRoot(data: dataType[], controls: Control[]) {
    //Group data by section
    const n_data = d3.group(data, (d) => d.section)

    const controls_sect = getControls(controls[0].data)

    //Clean data from Map to an array so VisX hierarchy can use it
    const n_data_arr = []

    n_data.forEach((d, i) =>
        n_data_arr.push({
            name: d[0].section,
            children: d,
            size: d.length,
            id: `parent-node${i.replace(/\s/g, '')}`,
        }),
    )

    let filtered_data = n_data_arr.splice(0)

    filtered_data = filtered_data.filter((d) =>
        controls_sect.includes('All') ? true : controls_sect.includes(d.name),
    )

    //Defining pack as the root node, and the data starts as children
    let pack = { children: filtered_data, name: 'root', radius: 0, distance: 0 }

    //Value to determine the ratio of size between higher and lower priority bubbles
    const size_factor = 2

    //Defining hierarchy using the priority as the sum
    //Sorted initially by size of children (Ordering the sections as parents), then doing the same for the individual issues but by priority
    const root = hierarchy(pack)
        .sum((d) => Math.pow(d.priority, size_factor))
        .sort(
            (a, b) =>
                (b.data.size ? b.data.size : -1) -
                (a.data.size ? a.data.size : -1),
        )

    return root
}

export function Bubbles({
    width,
    height,
    // data,
    // controls,
    // priority,
    changeInfo,
    changeHoverTitle,
}: bubbleProps) {
    const data = useBubbleStore((s) => s.issues)
    const controls = useBubbleStore((s) => s.controls)
    const priority = useBubbleStore((s) => s.priority)

    //Function that generates the root hierarchy
    const root = getRoot(data, controls)

    const controls_type = getControls(controls[1].data)

    //Initialises the colours
    const colours = scaleOrdinal({
        domain: controls[0].data.map((d) => d.name).splice(1),
        range: [
            '#d04757',
            '#db7d50',
            '#e5b348',
            '#9db16b',
            '#69a7b7',
            '#a074be',
        ],
        // range: ["#79addc", "#ffc09f", "#ffee93", "#adf7b6", "#c3a9e1"]
    })

    function circleTap(data, x: number, y: number, r: number) {
        //Check if circle is a Section bubble
        if (!data.children) {
            changeInfo(data)
        }
    }

    function circleHover(name: string) {
        changeHoverTitle(name)
    }

    return width < 10 ? null : (
        <svg width={width} height={height}>
            <rect width={width} height={height} fill={'#f7f7f3'} />
            <Pack root={root} size={[width, height]}>
                {(packData) => {
                    let gen_circles = packData.descendants().splice(1)
                    const circles = gen_circles.filter((d) =>
                        d.children
                            ? true
                            : controls_type.includes('All')
                            ? d.data.priority >= priority
                            : controls_type.includes(d.data.type) &&
                              d.data.priority >= priority,
                    )

                    for (let i = 0; i < circles.length; i++) {
                        if (!circles[i].children) {
                            circles[i].r -= 3
                        }
                    }
                    return (
                        <Group>
                            <Group left={100}>
                                <AnimatePresence>
                                    {circles.map((circle, i) => (
                                        <Group key={circle.data.id}>
                                            <motion.circle
                                                initial={{
                                                    r: 0,
                                                    cx: circle.x,
                                                    cy: circle.y,
                                                }}
                                                animate={{
                                                    r: circle.r,
                                                    cx: circle.x,
                                                    cy: circle.y,
                                                }}
                                                exit={{ r: 0, opacity: 0 }}
                                                filter={''}
                                                onHoverStart={() =>
                                                    circleHover(
                                                        circle.children
                                                            ? ''
                                                            : circle.data.name,
                                                    )
                                                }
                                                whileHover={{
                                                    r: circle.children
                                                        ? circle.r
                                                        : circle.r + 3,
                                                    opacity: circle.children
                                                        ? 1
                                                        : 0.9,
                                                }}
                                                onHoverEnd={() =>
                                                    circleHover('')
                                                }
                                                opacity={
                                                    circle.children ? 1 : 0.4
                                                }
                                                fill={
                                                    circle.children
                                                        ? colours(
                                                              circle.data.name,
                                                          )
                                                        : 'white'
                                                }
                                                onTap={() =>
                                                    circleTap(
                                                        circle.data,
                                                        circle.x,
                                                        circle.y,
                                                        circle.r,
                                                    )
                                                }
                                            />
                                            {/* <Text x = {circle.x} y = {circle.y} width = {2 * circle.r - 20} textAnchor = {'middle'} verticalAnchor = {'middle'} style = {{textOverflow: 'ellipsis'}}>
                        {circle.children? '' : (circle.r < 50? '...' : circle.data.name)}
                      </Text> */}
                                        </Group>
                                    ))}
                                </AnimatePresence>
                            </Group>
                        </Group>
                    )
                }}
            </Pack>
        </svg>
    )
}
