import { styled } from '../stitches.config'

import * as RD from '@radix-ui/react-dialog'

export const Root = RD.Root

export const Overlay = styled(RD.Overlay, {
    backgroundColor: 'rgba(0, 0, 0, .15)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
})

export const Content = styled(RD.Content, {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    maxWidth: 'fit-content',
    maxHeight: '85vh',
    padding: '$4',
    marginTop: '-5vh',
    backgroundColor: 'white',
    borderRadius: 6,

    overflowY: 'auto',

    '&:focus': {
        outline: 'none',
    },
})

export const Close = styled(RD.Close, {
    width: 80,
    height: 30,
    backgroundColor: '',
    borderRadius: 4,
    border: '1px solid #f1f1f1',
    fontSize: '$2',
    '&:hover': {
        backgroundColor: '#f3f3f3',
        boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.05)',
        border: '1px solid #e1e1e1',
    },
    '&:focus': {
        outline: 'none',
        boxShadow: '0 0 0 1px #7073FF',
    },
})
