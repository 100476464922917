import { Issue } from '../data/api'
import { IssueIcon } from '../ds/Node'
import { IssuesStore, useIssuesStore } from '../data/issuesStore'
import { SanityDocument } from '@sanity/client'
// import { styled } from '../stitches.config'
// import * as Popover from '../ds/Popover'
// import { IssueDescription } from './descriptions/Issue'

type IssueDoc = SanityDocument<Issue>

const specficIssueSelector = (s: IssuesStore) => s.specificIssue
const onTypesSelector = (s: IssuesStore) => s.selectedIssueTypes
const onSectionsSelector = (s: IssuesStore) => s.selectedIssueSections

export function IssueIndicator({
    issue,
    iconSize = 'md',
}: {
    issue: IssueDoc
    iconSize?: 'sm' | 'md' | 'lg'
    x?: number
    y?: number
}) {
    const specificIssue = useIssuesStore(specficIssueSelector)
    const onTypes = useIssuesStore(onTypesSelector)
    const onSections = useIssuesStore(onSectionsSelector)

    const specificIssueChosen = Boolean(specificIssue)
    const isSpecificIssue =
        specificIssueChosen && specificIssue!._id === issue._id

    const isSelectedType = onTypes.has(issue.type)
    const isSelectedSection = onSections.has(issue.issueSection)

    const isOpaque = specificIssueChosen
        ? isSpecificIssue
        : isSelectedType && isSelectedSection

    return (
        <IssueIcon
            size={iconSize}
            priority={issue.priority}
            isOpaque={isOpaque}
        />
    )

    // return (
    //     <Popover.Root>
    //         <Popover.Trigger>
    //             <IssueIcon
    //                 size={iconSize}
    //                 priority={issue.priority}
    //                 isOpaque={isOpaque}
    //             />
    //         </Popover.Trigger>
    //         <Popover.Content>
    //             <>
    //                 <IssueDescription issueDoc={issue} size="xs" />
    //                 <Popover.Close>Close</Popover.Close>
    //             </>
    //         </Popover.Content>
    //     </Popover.Root>
    // )
}
