import { createCss, StitchesCss } from '@stitches/react'
export type { StitchesVariants } from '@stitches/react'

const stitches = createCss({
    theme: {
        colors: {
            beige: '#eae4e4',

            gray100: 'hsl(0,0%,98.8%)',
            gray200: 'hsl(0,0%,96.0%)',
            gray300: 'hsl(0,0%,93.7%)',
            gray400: 'hsl(0,0%,92.0%)',
            gray500: 'hsl(0,0%,89.5%)',
            gray600: 'hsl(0,0%,85.2%)',
            gray700: 'hsl(0,0%,80.0%)',
            gray800: 'hsl(0,0%,56.1%)',
            gray900: 'hsl(0,0%,43.9%)',
            gray1000: 'hsl(0,0%,7%)',

            red100: 'hsl(351,100%,98.5%)',
            red200: 'hsl(351,89%,96.0%)',
            red300: 'hsl(352,86%,93.4%)',
            red400: 'hsl(352,85%,90.3%)',
            red500: 'hsl(353,84%,86.4%)',
            red600: 'hsl(354,83%,80.7%)',
            red700: 'hsl(355,82%,71.7%)',
            red800: 'hsl(356,91%,59.0%)',
            red900: 'hsl(356,80%,47.1%)',
            red1000: 'hsl(356,80%,27.1%)',

            crimson100: 'hsl(332,100%,98.5%)',
            crimson200: 'hsl(332,87%,96.0%)',
            crimson300: 'hsl(333,84%,93.3%)',
            crimson400: 'hsl(333,83%,90.2%)',
            crimson500: 'hsl(334,82%,86.3%)',
            crimson600: 'hsl(335,81%,80.3%)',
            crimson700: 'hsl(336,80%,70.0%)',
            crimson800: 'hsl(336,88%,56.3%)',
            crimson900: 'hsl(336,79%,46.1%)',
            crimson1000: 'hsl(336,79%,26.1%)',

            pink100: 'hsl(322,100%,98.5%)',
            pink200: 'hsl(322,90%,95.8%)',
            pink300: 'hsl(322,87%,93.0%)',
            pink400: 'hsl(322,86%,89.9%)',
            pink500: 'hsl(322,85%,86.2%)',
            pink600: 'hsl(322,85%,80.3%)',
            pink700: 'hsl(322,84%,68.9%)',
            pink800: 'hsl(322,75%,60.0%)',
            pink900: 'hsl(322,80%,43.9%)',
            pink1000: 'hsl(322,80%,23.9%)',

            purple100: 'hsl(280,100%,99.0%)',
            purple200: 'hsl(279,75%,95.7%)',
            purple300: 'hsl(278,71%,92.4%)',
            purple400: 'hsl(278,69%,89.0%)',
            purple500: 'hsl(277,68%,85.2%)',
            purple600: 'hsl(275,67%,80.2%)',
            purple700: 'hsl(272,66%,68.1%)',
            purple800: 'hsl(272,53%,50.0%)',
            purple900: 'hsl(272,62%,44.1%)',
            purple1000: 'hsl(272,62%,24.1%)',

            violet100: 'hsl(252,100%,99.0%)',
            violet200: 'hsl(252,87%,96.4%)',
            violet300: 'hsl(252,85%,93.7%)',
            violet400: 'hsl(252,84%,90.7%)',
            violet500: 'hsl(252,83%,86.8%)',
            violet600: 'hsl(252,83%,80.8%)',
            violet700: 'hsl(252,82%,72.2%)',
            violet800: 'hsl(252,62%,54.9%)',
            violet900: 'hsl(250,55%,48.0%)',
            violet1000: 'hsl(250,55%,28.0%)',

            indigo100: 'hsl(226,100%,99.0%)',
            indigo200: 'hsl(226,83%,96.3%)',
            indigo300: 'hsl(226,80%,93.3%)',
            indigo400: 'hsl(226,79%,89.8%)',
            indigo500: 'hsl(226,78%,85.4%)',
            indigo600: 'hsl(226,77%,79.1%)',
            indigo700: 'hsl(226,76%,70.2%)',
            indigo800: 'hsl(226,69%,54.1%)',
            indigo900: 'hsl(226,70%,44.1%)',
            indigo1000: 'hsl(226,70%,24.1%)',

            blue100: 'hsl(206,100%,98.8%)',
            blue200: 'hsl(206,98%,95.8%)',
            blue300: 'hsl(206,97%,92.6%)',
            blue400: 'hsl(206,97%,88.9%)',
            blue500: 'hsl(206,97%,83.9%)',
            blue600: 'hsl(206,97%,76.7%)',
            blue700: 'hsl(206,97%,68.3%)',
            blue800: 'hsl(206,100%,50.0%)',
            blue900: 'hsl(211,100%,43.9%)',
            blue1000: 'hsl(211,73%,12%)',

            cyan100: 'hsl(185,78%,97.8%)',
            cyan200: 'hsl(185,73%,93.2%)',
            cyan300: 'hsl(186,71%,88.2%)',
            cyan400: 'hsl(186,71%,82.8%)',
            cyan500: 'hsl(187,70%,76.4%)',
            cyan600: 'hsl(187,69%,68.2%)',
            cyan700: 'hsl(188,68%,59.2%)',
            cyan800: 'hsl(190,88%,40.0%)',
            cyan900: 'hsl(190,90%,30.0%)',
            cyan1000: 'hsl(190,63%,8.0%)',

            teal100: 'hsl(165,100%,97.5%)',
            teal200: 'hsl(166,73%,93.1%)',
            teal300: 'hsl(166,66%,88.1%)',
            teal400: 'hsl(167,63%,82.3%)',
            teal500: 'hsl(168,60%,75.1%)',
            teal600: 'hsl(170,57%,65.2%)',
            teal700: 'hsl(172,54%,50.0%)',
            teal800: 'hsl(173,79%,36.7%)',
            teal900: 'hsl(174,100%,24.5%)',
            teal1000: 'hsl(174,70%,7%)',

            green100: 'hsl(130,100%,97.5%)',
            green200: 'hsl(131,72%,94.0%)',
            green300: 'hsl(132,63%,89.8%)',
            green400: 'hsl(134,58%,84.6%)',
            green500: 'hsl(136,55%,78.0%)',
            green600: 'hsl(139,52%,69.0%)',
            green700: 'hsl(144,48%,55.8%)',
            green800: 'hsl(145,62%,41.0%)',
            green900: 'hsl(148,69%,30.0%)',
            green1000: 'hsl(148,69%,10.0%)',

            lime100: 'hsl(85,86%,96.5%)',
            lime200: 'hsl(84,79%,92.6%)',
            lime300: 'hsl(83,76%,87.3%)',
            lime400: 'hsl(82,74%,80.7%)',
            lime500: 'hsl(81,73%,72.6%)',
            lime600: 'hsl(79,72%,62.8%)',
            lime700: 'hsl(76,74%,48.9%)',
            lime800: 'hsl(81,91%,40.0%)',
            lime900: 'hsl(78,80%,25.1%)',
            lime1000: 'hsl(78,80%,5.1%)',

            yellow100: 'hsl(55,100%,95.5%)',
            yellow200: 'hsl(55,93%,89.9%)',
            yellow300: 'hsl(54,90%,83.6%)',
            yellow400: 'hsl(54,89%,76.5%)',
            yellow500: 'hsl(53,88%,67.5%)',
            yellow600: 'hsl(52,88%,57.1%)',
            yellow700: 'hsl(52,88%,51.7%)',
            yellow800: 'hsl(49,97%,48.0%)',
            yellow900: 'hsl(40,80%,32.0%)',
            yellow1000: 'hsl(40,80%,12.0%)',

            orange100: 'hsl(40,100%,97.0%)',
            orange200: 'hsl(40,97%,93.2%)',
            orange300: 'hsl(39,97%,88.7%)',
            orange400: 'hsl(39,96%,83.0%)',
            orange500: 'hsl(38,96%,75.5%)',
            orange600: 'hsl(37,96%,65.5%)',
            orange700: 'hsl(36,96%,53.9%)',
            orange800: 'hsl(38,100%,53.9%)',
            orange900: 'hsl(27,65%,35.9%)',
            orange1000: 'hsl(27,65%,15.9%)',

            brown100: 'hsl(30,75%,98.0%)',
            brown200: 'hsl(30,67%,94.0%)',
            brown300: 'hsl(30,66%,90.0%)',
            brown400: 'hsl(29,64%,85.7%)',
            brown500: 'hsl(29,64%,80.5%)',
            brown600: 'hsl(29,62%,72.6%)',
            brown700: 'hsl(28,61%,61.6%)',
            brown800: 'hsl(28,48%,52.0%)',
            brown900: 'hsl(20,50%,37.1%)',
            brown1000: 'hsl(20,50%,17.1%)',
        },
        fonts: {
            inter: 'Inter,sans-serif',
            alegreya: 'Alegreya,serif',
            heebo: 'Heebo,sans-serif',
        },
        space: {
            1: '5px',
            2: '10px',
            3: '15px',
            4: '20px',
            5: '25px',
            6: '35px',
            7: '45px',
            8: '65px',
            9: '80px',
        },
        sizes: {
            1: '5px',
            2: '10px',
            3: '15px',
            4: '20px',
            5: '25px',
            6: '35px',
            7: '45px',
            8: '65px',
            9: '80px',
        },
        fontSizes: {
            1: '12px',
            2: '13px',
            3: '15px',
            4: '17px',
            5: '19px',
            6: '21px',
            7: '27px',
            8: '35px',
            9: '59px',
        },
        radii: {
            1: '3px',
            2: '5px',
            3: '7px',
            round: '50%',
            pill: '9999px',
        },
        zIndices: {
            1: '100',
            2: '200',
            3: '300',
            4: '400',
            max: '999',
        },
    },
    // media: {
    //     bp1: '(min-width: 520px)',
    //     bp2: '(min-width: 900px)',
    //     bp3: '(min-width: 1200px)',
    //     bp4: '(min-width: 1800px)',
    //     motion: '(prefers-reduced-motion)',
    //     hover: '(any-hover: hover)',
    //     dark: '(prefers-color-scheme: dark)',
    //     light: '(prefers-color-scheme: light)',
    // },
    utils: {
        p: (config) => (value: any) => ({
            paddingTop: value,
            paddingBottom: value,
            paddingLeft: value,
            paddingRight: value,
        }),
        pt: (config) => (value: any) => ({
            paddingTop: value,
        }),
        pr: (config) => (value: any) => ({
            paddingRight: value,
        }),
        pb: (config) => (value: any) => ({
            paddingBottom: value,
        }),
        pl: (config) => (value: any) => ({
            paddingLeft: value,
        }),
        px: (config) => (value: any) => ({
            paddingLeft: value,
            paddingRight: value,
        }),
        py: (config) => (value: any) => ({
            paddingTop: value,
            paddingBottom: value,
        }),

        m: (config) => (value: any) => ({
            marginTop: value,
            marginBottom: value,
            marginLeft: value,
            marginRight: value,
        }),
        mt: (config) => (value: any) => ({
            marginTop: value,
        }),
        mr: (config) => (value: any) => ({
            marginRight: value,
        }),
        mb: (config) => (value: any) => ({
            marginBottom: value,
        }),
        ml: (config) => (value: any) => ({
            marginLeft: value,
        }),
        mx: (config) => (value: any) => ({
            marginLeft: value,
            marginRight: value,
        }),
        my: (config) => (value: any) => ({
            marginTop: value,
            marginBottom: value,
        }),

        // ta: (config) => (value: any) => ({ textAlign: value }),

        // fd: (config) => (value: any) => ({ flexDirection: value }),
        // fw: (config) => (value: any) => ({ flexWrap: value }),

        // ai: (config) => (value: any) => ({ alignItems: value }),
        // ac: (config) => (value: any) => ({ alignContent: value }),
        // jc: (config) => (value: any) => ({ justifyContent: value }),
        // as: (config) => (value: any) => ({ alignSelf: value }),
        // fg: (config) => (value: any) => ({ flexGrow: value }),
        // fs: (config) => (value: any) => ({ flexShrink: value }),
        // fb: (config) => (value: any) => ({ flexBasis: value }),

        // bc: (config) => (value: any) => ({
        //     backgroundColor: value,
        // }),

        // br: (config) => (value: any) => ({
        //     borderRadius: value,
        // }),
        // btrr: (config) => (value: any) => ({
        //     borderTopRightRadius: value,
        // }),
        // bbrr: (config) => (value: any) => ({
        //     borderBottomRightRadius: value,
        // }),
        // bblr: (config) => (value: any) => ({
        //     borderBottomLeftRadius: value,
        // }),
        // btlr: (config) => (value: any) => ({
        //     borderTopLeftRadius: value,
        // }),

        // bs: (config) => (value: any) => ({ boxShadow: value }),

        // lh: (config) => (value: any) => ({ lineHeight: value }),

        // ox: (config) => (value: any) => ({ overflowX: value }),
        // oy: (config) => (value: any) => ({ overflowY: value }),

        // pe: (config) => (value: any) => ({ pointerEvents: value }),

        size: (config) => (value: any) => ({
            width: value,
            height: value,
        }),
    },
})

export type CSS = StitchesCss<typeof stitches>

export const {
    styled,
    css,
    theme,
    getCssString,
    global,
    keyframes,
    config,
} = stitches

export const utils = config.utils
