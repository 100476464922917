import { styled, css } from '../stitches.config'
import * as RD from '@radix-ui/react-dialog'
import * as RadixSeparator from '@radix-ui/react-separator'

export const Root = RD.Root

export const Overlay = styled(RD.Overlay, {
    backgroundColor: 'rgba(0, 0, 0, .75)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
})

export const sdLogo = css({
    width: 'auto',
    height: 35,
    color: '#121212',
    flexShrink: 0,
    flexGrow: 0,
})

export const tfnswLogo = css({
    width: 'auto',
    height: 40,
    flexShrink: 0,
    flexGrow: 0,
})

export const Title = styled('h1', {
    fontFamily: '$heebo',
    fontWeight: 900,
    fontSize: '$8',
    textTransform: 'uppercase',
    letterSpacing: 0.3,
    color: '#3353FF',
})

export const SubTitle = styled('h2', {
    fontFamily: '$heebo',
    fontWeight: 900,
    fontSize: '$7',
    textTransform: 'uppercase',
    letterSpacing: 0.1,
    color: '$gray800',
})

export const P = styled('p', {
    fontFamily: '$inter',

    variants: {
        size: {
            md: {
                fontSize: '$4',
            },
            lg: {
                fontSize: '$6',
            },
        },
    },
})

export const LogoSeperator = styled(RadixSeparator.Root, {
    backgroundColor: '#eae4e4',
    width: 1,
    height: 35,
    mx: 20,
})

export const LogoGroup = styled('div', {
    display: 'flex',
    alignItems: 'center',
    pb: '$4',
})

export const Content = styled(RD.Content, {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // minWidth: 300,
    maxWidth: 700,
    maxHeight: '85vh',
    padding: '$6',
    marginTop: '-5vh',
    backgroundColor: 'white',
    borderRadius: 6,

    display: 'grid',
    gap: '$4',

    overflowY: 'auto',

    '&:focus': {
        outline: 'none',
    },
})

export const Close = styled(RD.Close, {
    width: 80,
    height: 30,
    backgroundColor: '',
    borderRadius: 4,
    border: '1px solid #f1f1f1',
    fontSize: '$2',
    '&:hover': {
        backgroundColor: '#f3f3f3',
        boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.05)',
        border: '1px solid #e1e1e1',
    },
    '&:focus': {
        outline: 'none',
        boxShadow: '0 0 0 1px #7073FF',
    },
})
