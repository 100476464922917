import { styled } from '../stitches.config'

export const TestWrapper = styled('div', {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
})

export const ControlCont = styled('div', {
    width: '100%',
    height: 'auto',
    borderRadius: 10,
})

export const TitlePopUpCont = styled('div', {
    position: 'fixed',
    right: '5vw',
    top: '5vh',

    padding: 20,
    width: 250,
    minHeight: 50,
    height: 'auto',

    fontSize: 16,
    verticalAlign: 'middle',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: 'white',

    borderRadius: 10,
    border: '1px solid #d1d1d1',

    transition: 'opacity 0.4s',

    variants: {
        visibility: {
            shown: {
                opacity: 1,
            },
            hidden: {
                opacity: 0,
            },
        },
    },
})
