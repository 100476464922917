import { styled } from '../stitches.config'

export const DL = styled('dl', {
    display: 'grid',

    variants: {
        layout: {
            vertical: {},
            horizontal: {
                gridTemplateColumns: '15ch 1fr',
                gridRowGap: '$1',
            },
        },

        size: {
            md: {
                gridTemplateColumns: '15ch 1fr',
            },
            sm: {
                gridTemplateColumns: '10ch 1fr',
            },
        },
    },

    defaultVariants: {
        layout: 'horizontal',
    },
})

export const DT = styled('dt', {
    fontFamily: '$inter',
    color: '$gray800',

    variants: {
        size: {
            xs: {
                fontSize: '$1',
            },
            sm: {
                fontSize: '$2',
            },
            md: {
                fontSize: '$3',
            },
        },
    },

    defaultVariants: {
        size: 'md',
    },
})

export const DD = styled('dd', {
    fontFamily: '$inter',
    fontSize: '$3',

    variants: {
        size: {
            xs: {
                fontSize: '$1',
            },
            sm: {
                fontSize: '$2',
            },
            md: {
                fontSize: '$3',
            },
        },
    },

    defaultVariants: {
        size: 'md',
    },
})
