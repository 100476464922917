import { useCallback } from 'react'
import { getBezierPath, getMarkerEnd, EdgeProps } from 'react-flow-renderer'
import { EcoEdge } from '../data/api'
import { useDataStore } from '../data/store'
import { styled } from '../stitches.config'

type Props = EdgeProps<EcoEdge>

const Path = styled('path', {
    fill: 'none',

    // This could be way easier with one variant ...
    variants: {
        edgeType: {
            normal: {
                stroke: '#b1b1b7',
                strokeWidth: '1px',
            },
            selected: {
                stroke: '#b1b1b7',
                strokeWidth: '6px',
            },
            issueSource: {
                stroke: '$red800',
                strokeWidth: '8px',
            },
            issueConnected: {
                stroke: '$orange700',
                strokeWidth: '6px',
            },
        },
    },

    defaultVariants: {
        edgeType: 'normal',
    },
})

type EdgeType = 'normal' | 'selected' | 'issueSource' | 'issueConnected'

export function CustomEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    arrowHeadType,
    markerEndId,
}: Props) {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })

    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)

    let edgeType: EdgeType = 'normal'

    const isSelected = useDataStore(
        useCallback((s) => s.highlighted.includes(id), [id]),
    )
    const isIssueSource = useDataStore(
        useCallback((s) => s.sourceIssueElements?.includes(id), [id]),
    )
    const isIssueConnected = useDataStore(
        useCallback((s) => s.connectedIssueElements?.includes(id), [id]),
    )

    if (isIssueSource) {
        edgeType = 'issueSource'
    } else if (isIssueConnected) {
        edgeType = 'issueConnected'
    } else if (isSelected) {
        edgeType = 'selected'
    }

    return (
        <Path id={id} d={edgePath} markerEnd={markerEnd} edgeType={edgeType} />
    )
}
