// @ts-nocheck
import { useState } from 'react'
import { ParentSize } from '@visx/responsive'
import { BubbleIssue, Control, useBubbleStore, Check } from './bubbleStore'

import { Bubbles } from './Bubbles'
import { Controls } from './Controls'
import { InfoPanel } from './InfoViewPanel'
import uniq from 'lodash.uniq'

import { TitlePopUpCont } from './components'

interface viewData {
    section: string
    name: string
    description: string
    priority: number
    type: string
}

function TitlePopUp(title: string) {
    return (
        <TitlePopUpCont visibility={title.title ? 'shown' : 'hidden'}>
            {title.title}
        </TitlePopUpCont>
    )
}

export default function App() {
    const data = useBubbleStore((d) => d.issues)
    const ready = useBubbleStore((d) => d.ready)
    const [controls, changeControls] = useBubbleStore((s) => [
        s.controls,
        s.changeControls,
    ])
    const [priority, setPriority] = useBubbleStore((s) => [
        s.priority,
        s.setPriority,
    ])

    const [infoPanelData, setInfoPanelData] = useState({
        section: 'none',
        name: 'none',
        description: 'none',
        priority: 0,
        type: 'none',
    })
    const [hoverTitle, setHoverTitle] = useState('')
    const [infoOpen, changeInfoOpen] = useState(false)

    function changePriority(n: number) {
        setPriority(n)
    }

    function changeViewInfo(data: viewData) {
        setInfoPanelData({
            section: data.section,
            name: data.name,
            description: data.description,
            priority: data.priority,
            type: data.type,
        })

        changeInfoOpen(true)
    }

    function changeHoverTitle(name: string) {
        setHoverTitle(name)
    }

    function changeActive(b: boolean) {
        console.log('it is:', b)
        if (!b) {
            changeInfoOpen(false)
        }
    }

    return (
        <>
            {/* <Controls
                control_options={controls}
                changeControls={changeControls}
                changePriority={changePriority}
            /> */}
            <InfoPanel
                data={infoPanelData}
                active={infoOpen}
                changeActive={changeActive}
            />
            <TitlePopUp title={hoverTitle} />
            <ParentSize>
                {({ width, height }) =>
                    ready &&
                    width > 10 && (
                        <Bubbles
                            width={width}
                            height={height}
                            changeInfo={changeViewInfo}
                            changeHoverTitle={changeHoverTitle}
                        />
                    )
                }
            </ParentSize>
        </>
    )
}
