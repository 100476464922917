// @ts-nocheck
import { ControlCont } from './components'
import { styled } from '../stitches.config'
import * as Checkbox from '@radix-ui/react-checkbox'
import * as Slider from '@radix-ui/react-slider'
import { scaleOrdinal } from '@visx/scale'
import { useBubbleStore } from './bubbleStore'

const ControlSubCont = styled('div', {
    width: '100%',
    height: 'auto',

    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #d1d1d1',
})

const ControlHeader = styled('div', {
    width: 'auto',
    marginLeft: 10,
    marginTop: 10,
    fontWeight: 'bold',
})

const ControlItem = styled('div', {
    width: 'calc(100% - 10)',
    margin: '2px 5px 2px 15px',
    height: 35,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
})

const StyledCheckBox = styled(Checkbox.Root, {
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    boxShadow: 'inset 0 0 0 1px gainsboro',
    width: 25,
    height: 25,
    borderRadius: 5,
    margin: 5,

    flex: 'start',

    '&:focus': {
        border: 'none',
        boxShadow: 'none',
    },
})

const ControlText = styled('div', {
    flex: 5,
    height: '100%',
    textOverflow: 'ellipsis',
    lineHeight: 3,
    fontSize: 11,
    textAlign: 'left',
})

const ControlColour = styled('div', {
    borderRadius: 100,
    width: 25,
    height: 15,
    marginTop: 10,
    marginRight: 5,
    flex: 'end',
})

const CheckIcon = styled('div', {
    width: '60%',
    height: '60%',
    margin: '20%',
    backgroundColor: '#3353FF',
    borderRadius: 3,
})

//SLIDER COMPONENTS
const ControlSlider = styled(Slider.Root, {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: 30,
    margin: '10px 30px 20px 30px',
})

const SliderTrack = styled(Slider.Track, {
    backgroundColor: 'gainsboro',
    position: 'relative',
    flexGrow: 1,
    height: 2,
})

const SliderRange = styled(Slider.Range, {
    position: 'absolute',
    backgroundColor: '#3867B2',
    borderRadius: '9999px',
    height: '100%',
})

const SliderThumb = styled(Slider.Thumb, {
    display: 'block',
    width: 25,
    height: 25,
    backgroundColor: 'white',
    border: '1px solid lightgray',
    borderRadius: '20px',
    '&:hover': {
        backgroundColor: '#f3f3f3',
    },
    '&:focus': {
        outline: 'none',
        borderColor: '#3867B2',
    },
})

interface ControlOptionType {
    name: string
    checked: boolean
}

export function Controls() {
    const { ready, priority, setPriority, controls, changeControls } =
        useBubbleStore()

    if (!ready) {
        return null
    }

    function handleCheck(s: 'Sections' | 'Type', n: string) {
        changeControls(s, n)
    }

    function handleSliderChange(n: number) {
        setPriority(n)
    }

    console.log(
        'controls:',
        controls[0].data.map((d) => d.name),
    )

    const colours = scaleOrdinal({
        domain: controls[0].data.map((d) => d.name),
        range: [
            '',
            '#d04757',
            '#db7d50',
            '#e5b348',
            '#9db16b',
            '#69a7b7',
            '#a074be',
        ],
    })

    const priorities = [
        'Low',
        'Low - Medium',
        'Medium',
        'Medium - High',
        'High',
    ]

    return (
        <ControlCont>
            {controls.map((s, cat) => {
                return (
                    <ControlSubCont>
                        <ControlHeader>{s.name}</ControlHeader>
                        {s.data.map((d) => {
                            return (
                                <ControlItem key={d.name}>
                                    <StyledCheckBox
                                        onCheckedChange={() =>
                                            handleCheck(s.name, d.name)
                                        }
                                        checked={d.checked}
                                    >
                                        <Checkbox.Indicator>
                                            <CheckIcon />
                                        </Checkbox.Indicator>
                                    </StyledCheckBox>
                                    <ControlText>{d.name}</ControlText>
                                    <ControlColour
                                        css={{
                                            backgroundColor:
                                                cat === 0
                                                    ? colours(d.name)
                                                    : 'none',
                                        }}
                                    />
                                </ControlItem>
                            )
                        })}
                    </ControlSubCont>
                )
            })}
            <ControlHeader>{`Priority: ${priorities[priority - 1]}${
                priority < 5 ? ' +' : ''
            }`}</ControlHeader>
            <ControlSlider
                defaultValue={[1]}
                min={1}
                max={5}
                step={1}
                onValueChange={handleSliderChange}
            >
                <SliderTrack>
                    <SliderRange />
                </SliderTrack>
                <SliderThumb />
            </ControlSlider>
        </ControlCont>
    )
}
