import { SVGProps } from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 126 136"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M114.4 80.7l-5.6 23.8-5.8-23.8h-8l-5.9 23.5-5.4-23.5h-8.8l9.7 37.1h8.6l5.7-23.5 5.8 23.5h8.5l.1-.3 9.8-36.8h-8.7zM34 104.4L13.7 80.7H5.1v37.1h8.4V94.1l20.2 23.7h8.7V80.7H34v23.7zM4.2 129.2c0-3.2 2.5-6 6-6 2 0 3.2.6 4.4 1.6l-1.3 1.5c-.9-.8-1.8-1.2-3.2-1.2-2.1 0-3.7 1.9-3.7 4.1 0 2.4 1.6 4.1 3.9 4.1 1.1 0 2.1-.3 2.8-.9v-2.1h-3v-1.8H15v4.8c-1.1 1-2.8 1.8-4.8 1.8-3.6 0-6-2.5-6-5.9zM26.2 129.2c0-2.3-1.6-4.1-3.9-4.1s-3.9 1.8-3.9 4.1c0 2.2 1.6 4.1 3.9 4.1s3.9-1.8 3.9-4.1zm-10 0c0-3.3 2.5-6 6.1-6 3.5 0 6 2.7 6 5.9s-2.5 5.9-6.1 5.9c-3.5.1-6-2.5-6-5.8zM28.2 123.4h2.2l3.5 8.9 3.4-8.9h2.2L34.7 135H33l-4.8-11.6zM40.9 123.4h8.6v1.9h-6.6v2.9h5.8v1.8h-5.8v3.1h6.6v1.8h-8.6v-11.5zM56.5 129.1c1.4 0 2.4-.8 2.4-1.9 0-1.2-.9-1.9-2.4-1.9h-3v3.9h3v-.1zm-5-5.7h5.1c1.4 0 2.6.4 3.3 1.1.6.6 1 1.5 1 2.5 0 1.9-1.1 3-2.8 3.5l3.1 4.4h-2.4l-2.8-4h-2.5v4h-2v-11.5zM62.7 123.4h1.9l6.2 8v-8h2v11.5h-1.7l-6.4-8.1v8.1h-2v-11.5zM74.8 123.4H77l3.5 5.5 3.5-5.5h2.2v11.5h-2v-8.2l-3.7 5.4h-.1l-3.6-5.4v8.2h-2v-11.5zM88.1 123.4h8.6v1.9h-6.5v2.9h5.7v1.8h-5.7v3.1h6.6v1.8h-8.7v-11.5zM98.8 123.4h1.8l6.2 8v-8h2v11.5h-1.7l-6.3-8.1v8.1h-2v-11.5zM114.1 125.3h-3.7v-1.9h9.4v1.9h-3.7v9.6h-2v-9.6zM44.7 112.7l4.9-5.9c3.4 2.8 7 4.6 11.3 4.6 3.4 0 5.5-1.3 5.5-3.6v-.1c0-2.1-1.3-3.2-7.6-4.8-7.6-1.9-12.6-4-12.6-11.5v-.1c0-6.8 5.5-11.4 13.3-11.4 5.5 0 10.2 1.7 14.1 4.8l-4.3 6.2c-3.4-2.3-6.7-3.7-9.9-3.7s-4.9 1.5-4.9 3.3v.1c0 2.5 1.6 3.3 8.2 5 7.7 2 12 4.7 12 11.3v.1c0 7.5-5.7 11.7-13.9 11.7-5.8 0-11.6-2-16.1-6z"
                fill="#15397F"
            />
            <path
                d="M29.5 56.1c3.3 5.6 7.5 9.4 12.4 11 1.5.5 3.1.8 4.7.9-.3-.2-.6-.5-.9-.8-2.4-2-5-5.2-6.5-9.7-1.6-4.9-2.4-10.7-2.4-17.2 0-9 1.5-16.8 2-18.9v-.1c.3-1.3 1-2.3 2-2.8-.4-.3-.9-.6-1.3-.8-6.3-3.9-11.6-6.2-12.7-6.7-.7-.3-1.1-.4-1.1-.4-.3-.1-.5-.2-.7-.2-.8 0-1.2.5-1.4 1.4 0 0-.2 1.4-.4 3.7-.2 2.1-.4 5.4-.4 9.4 0 1.1 0 2.2.1 3.3.2 8.4 1.7 19.3 6.6 27.9zM68.9 69.2c1.6 0 3.5-.4 5.4-1.3l1.2-.6c3.6-2 7.1-5.5 8.7-10.7 1.5-4.7 2.2-10.2 2.2-16.5 0-7.7-1.1-14.5-1.6-17-.2-.9-.3-1.4-.3-1.4-.1-.4-.3-1-.7-1-.2 0-.4.1-.6.2 0 0-.2.2-.6.5-.4.3-1 .8-1.7 1.5-1 .9-3.1 2.9-5.6 5.5-3.9 4.3-9 11.1-11.5 19-1.4 4.6-2.1 8.6-2.1 11.8 0 2.4.4 4.5 1.1 6.1.8 1.6 1.9 2.7 3.5 3.5.7.3 1.5.4 2.6.4z"
                fill="#E3173E"
            />
            <path
                d="M61.6 45.3c.1-.2.1-.3.2-.5-5.1-12.7-16.9-22.3-18.3-23.4-.4-.3-.6-.5-.6-.5-.2-.2-.4-.2-.6-.2-.4 0-.7.5-.8 1 0 .1-.1.6-.3 1.4-.1.7-.4 2-.7 4-.4 3.1-1 7.8-1 13 0 6.3.8 11.9 2.2 16.4 1.7 5.4 5.5 9 9.3 10.9.2.1.3.1.5.2 2.1 1 4.2 1.5 5.9 1.5 1 0 1.9-.2 2.7-.5.4-.2.8-.4 1.2-.7-.4-.5-.7-1-1-1.6-.9-1.9-1.4-4.3-1.4-7.3 0-3.6.8-7.9 2.3-12.6.1-.2.3-.6.4-1.1zM63.2 41.4c2.6-5.6 6.6-11.3 11.9-16.7-1.5-4.4-3.2-8.5-4.8-12-2.4-5.1-4.7-9.1-5.9-11-.4-.7-.6-1-.6-1-.4-.6-.7-.7-.8-.7-.3 0-.6.2-.9.7 0 0-.2.4-.6 1-.6 1-1.2 1.9-1.7 2.9-1 1.8-2.5 4.6-4.1 8.1-.4.8-.7 1.5-1.1 2.3-1.4 3.1-2.6 6.3-3.7 9.5 3.8 3.7 8.9 9.7 12.3 16.9zM85.1 18.4c1 .5 1.7 1.5 2 2.8v.1c.5 2.2 2 9.9 2 18.9 0 6.5-.8 12.3-2.3 17.2-1.5 4.6-4 7.7-6.4 9.7-.3.3-.6.5-.9.8 1.5-.1 3-.4 4.5-.8 4.8-1.6 9.1-5.3 12.4-11 4.9-8.6 6.4-19.6 6.7-28.2 0-1.1.1-2.2.1-3.2 0-3.2-.1-6.3-.4-9.4-.2-2.3-.4-3.6-.4-3.6-.2-.9-.6-1.3-1.4-1.4-.2 0-.4 0-.7.2 0 0-.4.1-1.1.4-.7.3-1.5.7-2.6 1.2l-.4.2c-3.3 1.6-6.5 3.4-9.6 5.3-.1.1-.2.1-.3.2-.5.1-.8.3-1.2.6zM76.7 21c2.8-2.2 5.8-4.2 9.2-6.3.2-.1.4-.2.5-.3-1-5.2-2.2-9-2.7-10.4v-.1c-.3-.7-.7-1.1-1.4-1.1H82c-.2 0-.4.1-.6.3 0 0-1.5.9-3.7 2.5-1.2.9-3.2 2.4-5.7 4.4.2.4.4.8.5 1.2 1.6 3.2 3 6.5 4.2 9.8zM49.2 21c1.2-3.2 2.6-6.6 4.2-9.9.2-.4.4-.8.5-1.1-1.9-1.6-3.8-3.1-5.7-4.4-2.3-1.6-3.7-2.5-3.7-2.5-.2-.2-.4-.2-.6-.3h-.3c-.6 0-1.1.4-1.4 1.1V4c-.5 1.4-1.6 5.3-2.7 10.4.2.1.4.3.6.4 3.3 2 6.3 4 9.1 6.2zM12.9 42.8c2.8-1.4 5.6-2.6 8.2-3.6-.5-3.2-.9-6.7-1-10.4 0-.5 0-1-.1-1.4-1.2-.1-2.2-.2-3.2-.3-2-.1-3.7-.2-4.9-.2h-1.1c-2 0-3.1.1-3.1.1-.8 0-1.5.5-1.9 1.2-.3.4-.4.9-.4 1.4.1 1.3 1 6.2 6.9 13.5.3-.2.4-.2.6-.3zM113.5 43c5.8-7.3 6.7-12.2 6.9-13.5 0-.5-.2-1.1-.4-1.4-.5-.7-1.2-1.1-2-1.2 0 0-.9 0-2.3-.1h-1.8c-1.3 0-3.2.1-5.5.2-.8.1-1.7.1-2.6.2 0 .5-.1 1-.1 1.4-.1 3.6-.4 7.1-1 10.4 2.7 1 5.5 2.2 8.3 3.6.2.3.4.3.5.4z"
                fill="#E3173E"
            />
            <path
                d="M124.8 52.9l-.9-.6c-.4-.3-1.1-.7-1.9-1.3-.2-.1-.4-.3-.7-.4-1.1-.7-2.7-1.7-4.5-2.7-.2-.1-.3-.2-.5-.3-1.2-.7-2.6-1.4-4.1-2.1-.3-.1-.5-.2-.8-.4-2.4-1.2-4.9-2.3-7.3-3.2-1.2 6-3.1 11.2-5.6 15.5-2.3 4-5.1 7.1-8.3 9.4-.4.3-.9.6-1.3.9 27.7-1.5 35.6-10.4 36.4-11.4.1-.2.2-.3.3-.5.1-.3.2-.5.2-.8 0-.9-.4-1.7-1-2.1zM35.5 66.8c-3.2-2.3-6-5.4-8.3-9.4-2.5-4.3-4.4-9.6-5.6-15.5-2.6 1-5 2-7.2 3.1-.4.2-.9.4-1.3.6-5.3 2.7-9.3 5.3-11.2 6.6l-.9.6c-.6.4-1 1.2-1 2 0 .4.1.7.3 1.1 0 .1.1.2.1.3v.1l.2.2.1.1c.2.2.6.7 1.3 1.2 1.2 1 3.3 2.5 6.6 4 7 3.2 16.7 5.1 28.1 5.8-.3-.2-.7-.5-1.2-.8zM100.8 69.7c-1.4 0-3.5.3-6.7.7-.5.1-1 .1-1.5.2-1.4.2-3.1.3-5.3.3-1.4 0-2.8 0-4.3-.1h-.1c-1.4 0-3.1-.1-4.7-.1-1.2 0-2.6 0-4.1.1-1.9.1-3.9.3-5.8.7-.6.1-1.2.2-1.8.4-.2.1-.3.3-.3.4 0 .1.3.2.5.2h.2c1.5-.1 3-.2 4.4-.2 5.1 0 8.5.9 11.8 1.7 3.1.8 6.1 1.5 10.2 1.5s7.5-.8 9-2.2l.3-.3c.5-.5.7-1.1.6-1.7 0-.2-.1-.4-.1-.6-.2-.6-.8-1-2.3-1zM59.5 70c-.6-.2-1.2-.3-1.8-.4-1.9-.4-3.8-.6-5.7-.7-1.5-.1-2.9-.1-4.2-.1-1.6 0-3.3 0-4.7.1H43c-1.5 0-2.9.1-4.3.1-2.3 0-3.9-.1-5.3-.3-.5-.1-1-.1-1.5-.2-3.2-.4-5.3-.7-6.7-.7-1.4 0-2 .3-2.2.9-.1.2-.1.5-.2.8-.1.6.2 1.2.6 1.7.2.2.4.4.7.6 1.7 1.2 4.9 1.9 8.6 1.9 4.1 0 7.1-.7 10.2-1.5 3.3-.8 6.8-1.7 11.8-1.7 1.4 0 2.9.1 4.4.2h.2c.2 0 .4 0 .5-.2 0-.2-.1-.5-.3-.5z"
                fill="#E3173E"
            />
        </svg>
    )
}

export default Icon
