// @ts-nocheck
import { styled } from '../stitches.config'
import * as Dialog from '@radix-ui/react-dialog'
import { AnimatePresence, motion } from 'framer-motion'

import { scaleOrdinal } from '@visx/scale'

import { Cross1Icon } from '@radix-ui/react-icons'

interface viewData {
    section: string
    name: string
    description: string
    priority: number
    type: string
}

// const colours = scaleOrdinal({
//   domain: controls[0].data.map((d) => d.name).splice(1),
//   range: ["#0081a7", "#00baae", "#e0646a", "#A340A6", "#edb124", "#23844F"]
// });

const StyledOverlay = styled(Dialog.Overlay, {
    backgroundColor: 'rgba(0, 0, 0, .15)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
})

const DialogCont = styled(Dialog.Content, {})

const InfoPanelCont = styled(motion.div, {
    overflow: 'scroll',
    width: 400,
    minHeight: 300,
    height: 'auto',
    position: 'fixed',
    left: 'calc(60vw - 200px)',
    marginTop: '20vh',

    display: 'flex',
    flexDirection: 'column',

    backgroundColor: 'white',
    boxShadow: '0 0 6px 1px rgba(0, 0, 0, 0.2)',

    outline: 'none',

    borderRadius: 10,
    border: '2px solid #fff',
})

const PanelHeader = styled('div', {
    width: '100%',
    height: 40,
    color: 'white',
    fontWeight: 'bold',
    paddingLeft: '15px',
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    justifyContent: 'space-between',
})

const PanelClose = styled(Dialog.Close, {
    width: 23,
    height: 23,
    flex: 'end',
    marginRight: 10,

    borderRadius: 100,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },

    '&:focus': {
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
    },
})

const PanelContent = styled('div', {
    padding: 10,
    width: '100%',
    height: '100%',
    flex: 5,

    display: 'flex',
    flexDirection: 'column',
})

const ContentName = styled('div', {
    width: '100%',

    fontWeight: 'bold',
    fontSize: 12,
    marginTop: 5,
    marginBottom: 10,
})

const ContentDescription = styled('div', {
    width: '100%',

    fontSize: 12,
})

const ContentFooter = styled('div', {
    width: '100%',
    height: 40,

    fontSize: 12,

    display: 'flex',
    flexDirection: 'row',
    display: 'flex',

    borderTop: 'solid 1px #d1d1d1',
})

const FooterType = styled('div', {
    flex: 1,
    paddingLeft: '15px',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
})

const FooterPriority = styled('div', {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
})

interface PanelProps {
    data: viewData
    active: boolean
}

export function InfoPanel({ data, active, changeActive }: PanelProps) {
    function handleOpen(n) {
        changeActive(n)
    }

    const colours = scaleOrdinal({
        domain: [
            'Overall',
            'Maintenance & Delivery',
            'Regional Assets',
            'Strategic Contracts',
            'Regional Property and Asset Renewal',
            'Safety and Environment',
        ],
        range: [
            '#d04757',
            '#db7d50',
            '#e5b348',
            '#9db16b',
            '#69a7b7',
            '#a074be',
        ],
    })

    const priorities = [
        'Low',
        'Low - Medium',
        'Medium',
        'Medium - High',
        'High',
    ]

    return (
        <Dialog.Root open={active} onOpenChange={handleOpen}>
            <StyledOverlay />
            <DialogCont>
                <AnimatePresence>
                    <InfoPanelCont
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <PanelHeader
                            css={{ backgroundColor: colours(data.section) }}
                        >
                            {data.section}
                            <PanelClose>
                                <Cross1Icon />
                            </PanelClose>
                        </PanelHeader>
                        <PanelContent>
                            <ContentName>{`Issue: ${data.name}`}</ContentName>
                            <ContentDescription>
                                {data.description}
                            </ContentDescription>
                        </PanelContent>
                        <ContentFooter>
                            <FooterType>
                                <span style={{ fontWeight: 'bold' }}>
                                    {'Type:'}
                                </span>
                                <p> &nbsp; </p>
                                {data.type}
                            </FooterType>
                            <FooterPriority>
                                <p style={{ fontWeight: 'bold' }}>
                                    {'Priority: '}
                                </p>
                                <p> &nbsp; </p>
                                <p> {priorities[data.priority - 1]} </p>
                            </FooterPriority>
                        </ContentFooter>
                    </InfoPanelCont>
                </AnimatePresence>
            </DialogCont>
        </Dialog.Root>
    )
}
